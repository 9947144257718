import { Component, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RuleService } from 'app/shared/rule.service';

@Component({
  selector: 'app-rule-engine-v2-test',
  standalone: false,
  templateUrl: './rule-engine-v2-test.component.html',
  styleUrl: './rule-engine-v2-test.component.css'
})
export class RuleEngineV2TestComponent {

  ruleId: number
  response = signal<any>(null);

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private ruleService: RuleService) {
    this.ruleId = +(activatedRoute.snapshot.params.ruleid);
  }

  runTest() {
    this.ruleService.testRuleNoUpdate(this.ruleId).then(response => {
      console.log(response);

      const params = response.checkRules.params;

      let inAlert = '';
      for (let index = 0; index < params.length; index += 2) {
        const param = params[index];
        const conditionId = params[index + 1].id
        if (conditionId === response.condition.id) {
          inAlert = param.inAlert;
        }
      }


      response.inAlert = inAlert;


      this.response.set(response);
    });
  }
}

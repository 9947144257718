<article>
  @defer (when site()) {
    <app-page-title [canGoBack]="true" (click)="goBack()">{{ site().title }}</app-page-title>

    <main class="content-container">
      <div>
        <div class="site-details">
          <app-page-title label="Site"></app-page-title>
          <table class="table table-sm" aria-label="site">
            <tbody>
              <tr>
                <td>Weather</td>
                <td><app-weather-temp [site]="site()" /></td>
              </tr>
              <tr>
                <td>Address</td>
                <td [innerHTML]="site().address.formattedForHTML" class="pt-1"></td>
              </tr>
              <tr>
                <td>Site online</td>
                <td>
                  {{ site().createdAt | amTimeAgo }}
                  <span>at {{ site().createdAt | date: 'dd/MM/YYYY' }}</span>
                </td>
              </tr>
              <tr *ngIf="site().regionId">
                <td>Region</td>
                <td>{{ site().regionTitle }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="tabview-list">
        <div [class.active]="tabIndex() === 0" (click)="tabIndex.set(0)"><i class="mdi mdi-account-multiple"></i> Notifications</div>
        <div [class.active]="tabIndex() === 1" (click)="tabIndex.set(1)"><i class="mdi mdi-view-list"></i> Collections</div>
        <div [class.active]="tabIndex() === 2" (click)="tabIndex.set(2)"><i class="mdi mdi-calendar-week-outline"></i> Operating Hours</div>
        @if (rulePackages()) {
          <div [class.active]="tabIndex() === 3" (click)="tabIndex.set(3)"><i class="mdi mdi-view-list"></i> Rules</div>
        }
        <div></div>
      </div>

      <div class="tabview-content">
        @switch (tabIndex()) {
          @case (1) {
            <app-site-collections [site]="site()" [combinedCollections]="combinedCollections()" (onUpdated)="getCollections()" />
          }
          @case (2) {
            <div class="mt-1">
              <app-site-hours [site]="site()" />
            </div>
          }
          @case (3) {
            <div class="mt-1">
              @if (dataTableRules) {
                <app-data-table [dataTable]="dataTableRules" (onRowClick)="rowClickRules($event)"></app-data-table>
              }
            </div>
          }
          @default {
            <app-site-notifications [site]="site()" />
          }
        }
      </div>
    </main>
  } @placeholder {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</article>

@if (wantsPolicy()) {
  <h1>Building Performance Report Terms and Conditions</h1>

  <h2>1. Purpose and Scope</h2>
  <p>This Building Performance Report (the "Report") has been prepared by 4D Monitoring Ltd ("we," "us," "our") to provide general recommendations on improving energy performance within buildings. The Report is based on the information available at the time of assessment and is intended to support decision-making by the building owner or manager (the "Client").</p>

  <h2>2. No Guarantee of Performance</h2>
  <p>The recommendations provided in this Report are for informational purposes only and do not guarantee specific performance outcomes. Energy savings, efficiency improvements, and other anticipated benefits may vary depending on multiple factors, including but not limited to installation quality, system maintenance, and building usage patterns.</p>

  <h2>3. Verification of Recommendations</h2>
  <p>It is the Client’s responsibility to ensure that any recommendations made within this Report are fully reviewed and verified against:</p>
  <ol type="a">
    <li>Manufacturer warranties, specifications, and maintenance requirements of any systems or equipment discussed;</li>
    <li>Relevant industry standards and regulatory requirements;</li>
    <li>Professional advice from suitably qualified specialists, including but not limited to HVAC maintenance contractors and professionally accredited HVAC consultants.</li>
  </ol>
  <p>The Client acknowledges that any modifications, upgrades, or changes to building systems or equipment should be managed by the building’s HVAC maintenance contractor, with the Client’s approval.</p>

  <h2>4. Limitation of Liability</h2>
  <p>We accept no liability for any loss, damage, costs, or expenses incurred as a result of implementing any recommendations provided in this Report. Specifically:</p>
  <ol type="a">
    <li>We shall not be responsible for any direct, indirect, or consequential damage resulting from reliance on the Report.</li>
    <li>Any modifications, upgrades, or changes to building systems or equipment undertaken based on this Report are at the Client’s own risk.</li>
    <li>We do not assume responsibility for ensuring compliance with planning permissions, building regulations, or other legal requirements.</li>
  </ol>

  <h2>5. Third-Party Services and Products</h2>
  <p>Where this Report references third-party services, products, or manufacturers, we do so for informational purposes only and do not endorse or guarantee the suitability of such products or services. It remains the Client’s responsibility to conduct due diligence and seek independent professional advice where appropriate.</p>

  <h2>6. Report Validity</h2>
  <p>This Report is based on site conditions and data available at the time of assessment. Changes in circumstances, regulations, or technology may impact the relevance or accuracy of the recommendations over time. We recommend that Clients review and update their building performance strategies regularly.</p>

  <h2>7. Governing Law</h2>
  <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising from or in connection with this Report shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>

  <p><strong>By using this Report, the Client acknowledges and agrees to these Terms and Conditions.</strong></p>
}

import { Component, signal } from '@angular/core';
import { Org } from 'app/classes/org';
import { RAGType } from 'app/interfaces/annotation-and-rags';
import { APIService } from 'app/shared/api.service';
import { IssueTrackingService } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-issue-tracking-admin',
  standalone: false,

  templateUrl: './issue-tracking-admin.component.html',
  styleUrl: './issue-tracking-admin.component.css'
})
export class IssueTrackingAdminComponent {

  org = signal<Org>(null);
  settings = signal<string[]>(null);
  isEnabled = signal<boolean>(false);
  hasLoaded = signal<boolean>(false);
  ragSelected = signal<RAGType>('amber');

  autoModeisEnabled = signal<boolean>(false);

  constructor(private apiServce: APIService, private issueTracking: IssueTrackingService) {
    this.getConfig();
  }

  getConfig() {
    this.issueTracking.getAdmin().then(orgConfig => {
      this.org.set(new Org(orgConfig.org));

      this.isEnabled = orgConfig.master.isEnabled;

      this.settings.set(orgConfig.settings);
      this.hasLoaded.set(true);
    });
  }

  setRAG(rag: RAGType) {
    this.ragSelected.set(rag);
  }

  enable() {
    this.apiServce.toastSuccess('Enabling issue tracking...', '', 500);
    this.issueTracking.enableForOrg().then(results => {
      this.getConfig();
      this.apiServce.toastSuccess('Enabled.', '', 1000);
    });
  }

  disable() {
    this.apiServce.toastSuccess('Disabling issue tracking...', '', 500);
    this.issueTracking.disableForOrg().then(results => {
      this.getConfig();
      this.apiServce.toastSuccess('Disabled.', '', 1000);
    });
  }
}

<article>
  <app-toolbar>
    <div class="left">
      {{ actionType() === 'asset-to-missing' ? 'Check not updated' : 'Check sensor value' }}
    </div>
    <div class="right">
      @if (index() !== null) {
        <app-button (click)="deleteConditionClick()" label="Delete" icon="mdi mdi-trash-can" [isMuted]="true" />
      }
      <app-button (click)="clickShowQuery()" icon="mdi mdi-rotate-3d-variant" [label]="showAssetQuery() ? 'Showing sensor chart' : 'Hiding sensor chart'"></app-button>
      <app-button (click)="submitCondition()" label="Save condition" icon="mdi mdi-check" />
    </div>
  </app-toolbar>

  <!-- Selected objects -->
  <div class="selection-container" [class.sensor-to-sensor]="selectionOnly">
    <div class="selected">
      @if (selectionOnly) {
        <div class="sensor-title" [ngClass]="{ 'is-selected': leftSelectedGateway && !leftSelectedAsset }">Sensor #1</div>
      }

      <div>
        <div class="left-label">Site</div>
        <div class="left-action">
          :

          @if (leftSelectedSite) {
            <app-button size="small" [label]="leftSelectedSite.title" icon="mdi mdi-close" (click)="cancelSite('left')" />
          }
        </div>
      </div>
      <div>
        <div class="left-label">Gateway</div>
        <div class="left-action">
          :

          @if (leftSelectedGateway) {
            <app-button size="small" [label]="leftSelectedGateway.title" icon="mdi mdi-close" (click)="cancelGateway('left')" />
          }
        </div>
      </div>
      <div>
        <div class="left-label">Sensor</div>
        <div class="left-action">
          :

          @if (leftSelectedAsset) {
            <app-button size="small" [label]="leftSelectedAsset.title" icon="mdi mdi-close" (click)="cancelAsset('left')" />
          }
        </div>
      </div>
    </div>

    @if (selectionOnly && leftSelectedSite) {
      <div class="selected">
        @if (selectionOnly) {
          <div class="sensor-title" [ngClass]="{ 'is-selected': selectionOnly && leftSelectedAsset && !rightSelectedAsset && rightSelectedGateway }">Sensor #2</div>
        }

        @if (rightSelectedSite) {
          <div>
            <div class="left-label">Site</div>
            <div class="left-action">: <app-button size="small" [label]="rightSelectedSite.title" icon="mdi mdi-close" (click)="cancelSite('right')" /></div>
          </div>
        }

        @if (rightSelectedGateway) {
          <div>
            <div class="left-label">Gateway</div>
            <div class="left-action">: <app-button size="small" [label]="rightSelectedGateway.title" icon="mdi mdi-close" (click)="cancelGateway('right')" /></div>
          </div>
        }

        @if (rightSelectedAsset) {
          <div>
            <div class="left-label">Sensor</div>
            <div class="left-action">: <app-button size="small" [label]="rightSelectedAsset.title" icon="mdi mdi-close" (click)="cancelAsset('right')" /></div>
          </div>
        }
      </div>
    }
  </div>

  <!-- Objects awaiting selection -->
  @if (!leftSelectedSite) {
    <div class="create-condition">
      <h4>Select a <strong>site</strong></h4>
      <app-site-picker (submit)="siteSelected('left', $event)"></app-site-picker>
    </div>
  }

  @if (selectionOnly && leftSelectedAsset && !rightSelectedSite) {
    <div class="create-condition">
      <h4>Select a <strong>site</strong> for sensor #2</h4>
      <app-site-picker (submit)="siteSelected('right', $event)"></app-site-picker>
    </div>
  }
  @if (leftSelectedSite && !leftSelectedGateway) {
    <div class="create-condition">
      <h4>Select a <strong>gateway</strong> for {{ leftSelectedSite.title }}</h4>
      <app-gateway-picker [site]="leftSelectedSite" (submit)="gatewaySelected('left', $event)"></app-gateway-picker>
    </div>
  }

  @if (selectionOnly && rightSelectedSite && leftSelectedGateway && !rightSelectedGateway) {
    <div class="create-condition">
      <h4>Select <strong>gateway</strong> for sensor #2</h4>
      <app-gateway-picker [site]="rightSelectedSite" (submit)="gatewaySelected('right', $event)"></app-gateway-picker>
    </div>
  }

  @if (leftSelectedGateway && !leftSelectedAsset) {
    <div class="create-condition">
      <h4>Select <strong>sensor #1</strong> for {{ leftSelectedGateway.title }}</h4>
      <app-sensor-picker [gateway]="leftSelectedGateway" (submit)="sensorSelected('left', $event)"></app-sensor-picker>
    </div>
  }

  @if (selectionOnly && leftSelectedAsset && !rightSelectedAsset && rightSelectedGateway) {
    <div class="create-condition">
      <h4>Select <strong>sensor #2</strong> for {{ rightSelectedGateway.title }}</h4>
      <app-sensor-picker [gateway]="rightSelectedGateway" (submit)="sensorSelected('right', $event)"></app-sensor-picker>
    </div>
  }

  <!------------------------------ Final section ----------------------------->

  @switch (actionType()) {
    @case ('asset-to-missing') {
      <div class="condition flex">
        <section class="flex-1">
          <h2>How long to wait for no communication</h2>
          <div>
            <i>This condition runs 24/7, independent of any restrictions set in the rule.</i>
          </div>
          @if (durationAsWords()) {
            <div class="mt-1">
              If this sensor does not update in
              <span class="duration-words">{{ durationAsWords() }}</span>
              then alarm
            </div>
          }
        </section>
        <section>
          <div class="value">
            <div class="flex">
              <div class="days">
                Days
                <input class="fourd" name="valueDays" type="number" [(ngModel)]="valueDays" (keyup)="changedDuration()" (blur)="changedDuration()" />
              </div>
              <div class="hours">
                Hours
                <input class="fourd" name="valueHours" type="number" [(ngModel)]="valueHours" (keyup)="changedDuration()" (blur)="changedDuration()" />
              </div>
            </div>
          </div>
        </section>
      </div>
    }
    @default {
      @if (leftSelectedAsset && !selectionOnly) {
        <div class="condition flex">
          <section class="flex-1">
            <h2>Condition</h2>
            <div class="asset">
              If sensor <i>{{ leftSelectedAsset.title }}</i> is
            </div>
            <div class="operator">
              <app-chip [isRadio]="true" [checked]="selectedOperator == '>'" (click)="operatorSelected('>')" label="Greater than"></app-chip>
              <app-chip [isRadio]="true" [checked]="selectedOperator == '>='" (click)="operatorSelected('>=')" label="greater than or equal"></app-chip>
              <app-chip [isRadio]="true" [checked]="selectedOperator == '='" (click)="operatorSelected('=')" label="Equals"></app-chip>
              <app-chip [isRadio]="true" [checked]="selectedOperator == '<='" (click)="operatorSelected('<=')" label="Less than or equal"></app-chip>
              <app-chip [isRadio]="true" [checked]="selectedOperator == '<'" (click)="operatorSelected('<')" label="Less than"></app-chip>
            </div>
          </section>
          <section>
            <div class="value">
              <h2>Value</h2>
              <div class="flex">
                <div><input class="fourd" name="value" [(ngModel)]="value" /></div>
                <div>
                  @if (clipboard) {
                    <app-button label="Use {{ clipboard }}" (click)="useClipboard()" icon="mdi mdi-arrow-left" />
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
        @if (showAssetQuery() && leftSelectedAsset) {
          <div class="chart">
            <app-setpoint-query [assets]="[leftSelectedAsset]" view="compact" [showValue]="false" [clickForValue]="true" (onValueChange)="onQueryValueChange($event)" />
          </div>
        }
      }

      @if (leftSelectedAsset && rightSelectedAsset && selectionOnly) {
        <div class="condition">
          <h2>Condition</h2>

          <div class="operator">
            <app-chip [isRadio]="true" [checked]="selectedOperator == '>'" (click)="operatorSelected('>')" label="Greater than"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == '>='" (click)="operatorSelected('>=')" label="greater than or equal"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == '='" (click)="operatorSelected('=')" label="Equals"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == '<='" (click)="operatorSelected('<=')" label="Less than or equal"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == '<'" (click)="operatorSelected('<')" label="Less than"></app-chip>

            <app-chip [isRadio]="true" [checked]="selectedOperator == 'diff'" (click)="operatorSelected('diff')" label="Absolute Difference"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == '<diff'" (click)="operatorSelected('<diff')" label="Difference less than"></app-chip>
            <app-chip [isRadio]="true" [checked]="selectedOperator == 'diff>'" (click)="operatorSelected('diff>')" label="Difference greater than"></app-chip>
          </div>

          <div>
            @if (selectedOperator === 'diff' || selectedOperator === '<diff' || selectedOperator === 'diff>') {
              <div class="value">
                <h2>Value</h2>
                <input class="fourd" name="value" [(ngModel)]="value" />
              </div>

              <div class="explain">
                <span *ngIf="selectedOperator !== 'diff' && selectedOperator !== '<diff' && selectedOperator !== 'diff>'">{{ leftSelectedAsset.title }} is </span>
                <span *ngIf="selectedOperator !== 'diff' && selectedOperator !== '<diff' && selectedOperator !== 'diff>'">
                  <strong> {{ selectedOperator }} </strong>
                </span>
                <span *ngIf="selectedOperator !== 'diff' && selectedOperator !== '<diff' && selectedOperator !== 'diff>'">{{ rightSelectedAsset.title }}</span>
                <span *ngIf="selectedOperator === 'diff' || selectedOperator === '<diff' || selectedOperator === 'diff>'"
                  >The
                  <span *ngIf="selectedOperator === 'diff'">absolute</span>
                  difference between {{ leftSelectedAsset.title }} and {{ rightSelectedAsset.title }} is <span *ngIf="selectedOperator === 'diff' || selectedOperator === 'diff>'">higher</span> <span *ngIf="selectedOperator === '<diff'">less</span> than the input.</span
                >
              </div>
            }
          </div>
        </div>
      }
    }
  }
</article>

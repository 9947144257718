import { Component, input, OnInit, signal } from '@angular/core';
import { BuildingReview } from 'app/classes/building-review';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-reports-policy',
  standalone: false,
  templateUrl: './reports-policy.component.html',
  styleUrl: './reports-policy.component.css'
})
export class ReportsPolicyComponent implements OnInit {

  review = input.required<BuildingReview>();
  wantsPolicy = signal<boolean>(false);

  constructor(private apiService: APIService) {
    // doesn't work if backend print pdf
    //   this.wantsPolicy.set(apiService.isAdmin() || apiService.getThisUser().email.endsWith('@4dml.com'));
  }

  ngOnInit(): void {
    this.wantsPolicy.set(this.review().createdBy.email.endsWith('@4dml.com'));
  }
}

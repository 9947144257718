import { Component, HostListener, input, OnDestroy, OnInit, output, signal, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { BuildingReview, BuildingReviewStateType } from 'app/classes/building-review';
import { BuildingReviewConfigItem } from 'app/classes/building-review-config';
import { Telemetry } from 'app/classes/telemetry';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { Title } from '@angular/platform-browser';
import { IGraphBlock } from 'app/charts/d3-chart/d3-chart.component';
import { IAnnotation, RAGType } from 'app/interfaces/annotation-and-rags';
import { IssueTrackingService } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-insights-review-item',
  templateUrl: './insights-review-item.component.html',
  styleUrl: './insights-review-item.component.css',
  standalone: false
})
export class InsightsReviewItemComponent implements OnInit, OnDestroy {
  static readonly CACHE_TELEMETRY_AGE = 86400;
  uuid = crypto.randomUUID();
  review = input.required<BuildingReview>();
  config = input.required<BuildingReviewConfigItem>();
  reviewState = signal<BuildingReviewStateType>(null);
  isLoadingData = true;
  assetCount: number;
  assetsLoaded = signal<number>(0)
  message = signal<string>('');
  assets = signal<IAssetContainer[]>(null);
  viewMode = signal<'edit' | 'print' | 'readonly'>('edit');
  outstandingAnnotations = true;
  onClose = output<boolean>();
  hasDropped: boolean;
  isDragging: boolean;
  uploadFile: File;
  dialogOpen = signal<'annotations' | 'rag' | 'complete'>(null);
  newAnnotationText = '';
  newAnnotationRag: RAGType = 'green';
  newRAG: RAGType;
  wantsPDFemailed: boolean;
  blocks = signal<IGraphBlock[]>(null);
  lastToastTime: number = Date.now();
  isPrinting = false;
  batteryAnnotation = signal<IAnnotation>(null);
  isAdmin: boolean;
  outstandingCount = null;


  @HostListener("dragenter", ["$event"])
  onDragStartHandler(event: DragEvent) {
    if (this.hasDropped) {
      return;
    }
    this.isDragging = true;
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
    console.log("dragstart", event);
  }

  @HostListener("dragover", ["$event"])
  onDragOverHandler(event: DragEvent) {
    if (this.hasDropped) {
      return;
    }
    this.isDragging = true;
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"])
  onDragLeaveHandler(event: any) {
    if (event.toElement.localName === "td") {
      return;
    }

    event.preventDefault();
    console.info("dragleave", event.toElement.localName, event);
  }

  @HostListener("drop", ["$event"])
  onDropHandler(event: DragEvent) {
    if (!this.isDragging) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    console.log("drop", event);
    this.isDragging = false;
    const file = event.dataTransfer.files[0];
    const { name } = file;

    this.uploadFile = file;

    if (!name || name.toLowerCase().substr(name.length - 3) !== "pdf") {
      return this.apiService.toastWarn("Not a PDF", `received ${name}`);
    }

    this.hasDropped = true;

    const building = this.review().building;
    const review = this.review().review;
    this.toastMessage("uploading", "please wait...");
    const title = 'Building Review for ' + building.title + ' from ' + review.dates.start.toLocaleDateString() + ' to ' + review.dates.end.toDateString();

    const filename = this.titleService.getTitle();
    this.apiService.postUploader(title, false, 'building_reviews', `${building.id}_${this.apiService.dbDate(review.dates.start)}`, this.uploadFile.type, filename, this.uploadFile).then((r) => {
      console.log(r);
      this.toastMessage("uploaded", "");
      this.hasDropped = false;
      this.isDragging = false;
    });
  }

  @ViewChildren('annotationDiv') editableDivs: QueryList<ElementRef>;

  constructor(private titleService: Title, private apiService: APIService, private buildingService: BuildingsService, private assetService: AssetService, private issueTracking: IssueTrackingService) {
    const localStorageString = localStorage.getItem(`building-reviews:config`);
    if (localStorageString) {
      const { wantsPDFemailed } = JSON.parse(localStorageString);
      this.wantsPDFemailed = wantsPDFemailed;
    }
  }

  ngOnInit(): void {
    this.isAdmin = this.apiService.isAdmin();
    this.initialiseReview();
    this.titleService.setTitle(`u_${this.apiService.getUserId()}_b_${this.review().building.id}_d_${+this.review().review.dates.start}_review.pdf`);
  }

  scrollToFirstEmptyAnnotation() {
    const firstEmpty = this.assets().find(ac => !ac.annotation?.text);
    if (firstEmpty) {
      this.scrollTo(`asset-container-${firstEmpty.asset.id}`);
    }
  }


  scrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  batteryFocusOutDIV(annotation: IAnnotation) {
    console.info('BATTERY batteryFocusOutDIV()', annotation);
    this.batteryAnnotation.set(annotation);
  }
  batteryRAGchange(annotation: IAnnotation) {
    console.info('BATTERY batteryRAGchange()', annotation);
    this.batteryAnnotation.set(annotation);
  }

  toggleBlocks() {
    if (this.blocks()?.length) {
      this.blocks.set(null);
      this.assets().forEach(ac => { ac.state = 'pending'; ac.telemetry = null; });
      this.assetsLoaded.set(0);
      this.getTelemetry();
    } else {
      this.calculateGraphBlocks();
    }
  }

  addToIssueTracking() {
    this.apiService.toastSuccess('Adding to Issue Tracking..', null, 500);
    this.issueTracking.addReview(this.review()).then(r => {
      this.apiService.toastSuccess('Added to Issue Tracking', null, 1000);
    });
  }

  calculateGraphBlocks() {
    this.blocks.set([]);
    const hours = this.config().building.openingHours.hours;
    const timeDiff = this.review().review.dates.end.getTime() - this.review().review.dates.start.getTime();
    const days = Math.round(timeDiff / (1000 * 3600 * 24));
    console.log(`hours are`, days, hours);

    const blocks: IGraphBlock[] = [];

    for (let dayIndex = 0; dayIndex < days; dayIndex++) {
      const date = new Date(this.review().review.dates.start.getTime() + (dayIndex * 1000 * 3600 * 24));
      let dow = date.getDay() - 1; // sun is 0
      if (dow === -1) {
        dow = 6;
      }
      console.log(dayIndex, date, dow);

      const hour = hours.find(h => h.dow === dow);
      if (hour) {

        {
          const from = new Date(date);
          const to = new Date(date);
          from.setHours(0);
          to.setHours(+hour.from.substring(0, 2));
          console.log('using hour', hour, from, to);
          blocks.push({
            from, to, colour: 'black', opacity: .7
          });
        }
        {
          const from = new Date(date);
          const to = new Date(date);
          from.setHours(+hour.to.substring(0, 2));
          to.setHours(+23);
          to.setMinutes(59);
          console.log('using hour', hour, from, to);
          blocks.push({
            from, to, colour: 'black', opacity: .7
          });
        }
      }
    }

    if (blocks.length) {
      this.blocks.set(blocks);
    }

    this.assets().forEach(ac => { ac.state = 'pending'; ac.telemetry = null; });
    this.assetsLoaded.set(0);

    this.getTelemetry();
  }

  changeRAG() {
    this.newRAG = this.review().review.rag;
    this.dialogOpen.set('rag');
  }

  async setRAG(rag: RAGType) {
    this.newRAG = rag;
    await this.saveRAG();
    this.dialogOpen.set(null);
  }

  closeRAGdialog() {
    this.newRAG = null;
    this.dialogOpen.set(null);
  }

  toastMessage(title, message = '', duration = 3000) {
    this.apiService.toastSuccess(title, message, duration);
    this.lastToastTime = Date.now();
  }

  async saveRAG(rag: RAGType = this.newRAG) {
    if (rag !== this.review().review.rag) {
      this.toastMessage(`Updating RAG to ${this.newRAG}`, 'please wait', 1000);
      await this.buildingService.updateReviewRAGForBuilding(this.review().building.id, this.review().review.dates.start, rag);
      this.review().review.rag = rag
      this.toastMessage('RAG updated.');
    }
    this.newRAG = null;
  }

  async manageAnnotation(action: 'delete' | 'click' | 'add' | 'rag', annotation: any = null) {
    switch (action) {
      case 'delete':
        this.toastMessage('Deleting annotation...', 'please wait');
        await this.buildingService.deleteCommonAnnotation(annotation.id);
        this.config().commonAnnotations = this.config().commonAnnotations.filter(a => a.id !== annotation.id);
        this.toastMessage('Annotation deleted.', '');
        break;
      case 'rag':
        this.newAnnotationRag = this.newAnnotationRag === 'green' ? 'amber' : this.newAnnotationRag === 'amber' ? 'red' : 'green';
        break;
      case 'add':
        {
          this.toastMessage('Adding annotation...', 'please wait');
          const annotationStored = await this.buildingService.addCommonAnnotation({ rag: this.newAnnotationRag, text: this.newAnnotationText });
          this.config().commonAnnotations.push(annotationStored);
          this.toastMessage('Annotation added.', '');
        }
        break;
    }
  }

  getAnnotationsFromDivs() {
    this.editableDivs.forEach((div) => {
      const content = div.nativeElement.innerText.trim();
      try {
        const assetId = +(div.nativeElement.className.split('-')[2].split(' ')[0] ?? 0);

        if (assetId) {
          console.log(content, content.length, assetId);
          this.assets().find(assetContainer => assetContainer.asset.id === assetId).annotation.text = content;
        }
      } catch (e) { }
    });
    this.annotationChanged();
  }

  setAnnotationAtDiv(assetId: number, text: any) {
    this.editableDivs.forEach((div) => {
      try {
        const assetIdAtDiv = +(div.nativeElement.className.split('-')[2].split(' ')[0] ?? 0);
        if (assetIdAtDiv === assetId) {
          div.nativeElement.innerText = text;
        }
      } catch (e) {

      }
    });
  }

  annotationCogClick() {
    this.dialogOpen.set('annotations');
  }

  annotationChanged() {
    console.log('annotationChanged()');
    if (this.isLoadingData) {
      return;
    }
  }

  async saveClick() {
    this.getAnnotationsFromDivs();
    this.calculateOutstandingAnnotations();
    if (this.review().state === 'init' || this.review().state === 'new') {
      if (this.outstandingAnnotations || (this.batteryAnnotation() && this.batteryAnnotation().text.length === 0)) {
        this.apiService.toastSuccess('Please annotate all assets to complete' + (this.batteryAnnotation() && this.batteryAnnotation().text.length === 0 ? ' + battery' : ''), '', 1000);
      } else {
        this.dialogOpen.set('complete');
      }
    }
    await this.buildingService.updateReviewAnnotationsForBuilding(this.review().building.id, this.review().review.dates.start, this.assets(), this.batteryAnnotation());
    this.toastMessage('Updated annotations', '', 500);
  }

  async completeReview() {
    localStorage.setItem(`building-reviews:config`, JSON.stringify({ wantsPDFemailed: this.wantsPDFemailed }));
    this.dialogOpen.set(null);
    let message = 'please wait';
    if (this.wantsPDFemailed) {
      message = `you'll be emailed a copy of the PDF`;
    }

    this.apiService.toastSuccess('Marking complete', message, 1500);
    await this.buildingService.completeReviewForBuilding(this.review().building.id, this.review().review.dates.start, this.wantsPDFemailed);
    this.apiService.toastSuccess('Generating PDF...', 'review will be marked done in around 1 minute', 2000);
    this.onClose.emit(true);
  }

  async deleteReview() {
    this.toastMessage('Delete in progress...', '', 1500);
    await this.buildingService.deleteReviewForBuilding(this.review().building.id, this.review().review.dates.start);
    this.toastMessage('Deleted', '');
    this.onClose.emit(true);
  }

  clickRag(asset: IAssetContainer) {
    let rag = asset.annotation.rag;
    switch (rag) {
      case 'green':
        rag = 'amber';
        break;
      case 'amber':
        rag = 'red';
        break;
      default: // handles null to green as well as red
        rag = 'green';
        break;
    }
    asset.annotation.rag = rag;
  }

  setEnd(endDate: Date) {
    this.review().review.dates.end = endDate;
    this.config().review.next.end = endDate;
  }

  getDates() {
    const start = this.review().review.dates.start || this.config().review.next.start;
    const end = this.review().review.dates.end || this.config().review.next.end;

    return { start, end };
  }

  initialiseReview() {
    const { start, end } = this.getDates();

    this.buildingService.initialiseReview(this.config().building.id, start, end, this.config().collection.id).then(status => {

      if (this.review().isNew && !status.isNew) {
        this.apiService.toastWarn('Unable to create', 'Review exists for this date', 5000);

        this.onClose.emit(true);
        return;
      }

      if (this.viewMode() === 'edit' && this.review().state === 'done') {
        // If the review is done, make sure it's not editable
        this.viewMode.set('readonly');
      }

      // When done, sort the assets in order of the RAG

      const assets = status.assets.map(a => {
        return {
          asset: new Asset({ id: a.id, title: a.title, assetType_id: a.assetType_id }),
          telemetry: null,
          state: 'pending',
          annotation: { rag: 'green', text: '' }
        };
      });


      this.assets.set(assets);
      console.log('Review loaded');

      this.assetCount = this.assets.length;
      this.assetsLoaded.set(0);

      if (status?.annotations) {
        status.annotations.forEach(a => {
          const asset = assets.find(asset => asset.asset.id === a.asset_id);
          if (asset) {
            asset.annotation = { rag: a.rag, text: a.annotation };
          }
        });

        if (this.review().state === 'done') {
          assets.sort((a, b) => a.annotation.rag === 'red' ? -2 : a.annotation.rag === 'amber' ? -1 : 1);
        }

        this.annotationChanged();
      }

      this.getTelemetry();
    });
  }

  printPage() {
    if (this.isPrinting) {
      console.log('waiting for toasts to expire');
      return;
    }
    this.isPrinting = true;
    this.getAnnotationsFromDivs();

    let waitFor = (Date.now() - this.lastToastTime);

    if (waitFor >= 3000 || waitFor <= 0) {

      waitFor = 10;
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.print();
        this.isPrinting = false;
      }, waitFor);
    });
  }


  getTelemetry() {
    console.log('GET_TELEMETRY');
    const getAsset = this.assets().find(a => a.state === 'pending');

    if (!getAsset) {
      console.log('All done!');
      this.assetsLoaded.set(this.assetCount);
      this.isLoadingData = false;
      this.calculateOutstandingAnnotations();
      this.scrollToFirstEmptyAnnotation();
      return;
    }
    console.log(getAsset?.state);

    this.assetService.getTelemetry(getAsset.asset.id, this.review().review.dates.start, this.review().review.dates.end, InsightsReviewItemComponent.CACHE_TELEMETRY_AGE, 15000, 0).then((response: any) => {
      getAsset.state = response.telemetry.length ? 'hasdata' : 'nodata';
      this.assetsLoaded.set(this.assetsLoaded() + 1);

      this.assets.update(assets => {
        const index = assets.findIndex(a => a.asset.id === getAsset.asset.id);
        assets[index] = { ...getAsset, telemetry: response.telemetry };

        return assets;
      });

      setTimeout(() => {
        console.log('timeout get_telemetry()');
        this.getTelemetry();
      }, 10);
    });
  }

  annotationRequested(asset: IAssetContainer, annotation: any) {
    console.log(`annotation_requested`, asset, annotation);
    if (!asset) {
      return;
    }
    this.assets.update(assets => {
      const index = assets.findIndex(a => a.asset.id === asset.asset.id);
      assets[index] = {
        ...asset, annotation: { rag: annotation.rag, text: annotation.text }
      };

      return assets;
    });

    this.setAnnotationAtDiv(asset.asset.id, annotation.text);
    this.annotationChanged();

    this.focusOutDIV();
  }

  getSupporting() {

  }

  focusOutDIV() {
    this.getAnnotationsFromDivs();
    this.calculateOutstandingAnnotations();
  }

  calculateOutstandingAnnotations() {
    this.outstandingCount = this.assets().filter(a => a.annotation.text.length === 0).length;
    this.outstandingAnnotations = !!this.outstandingCount;
  }

  annotationQuickButtonClick(assetContainer: any, annotation: any) {
    assetContainer.annotation = { rag: annotation.rag, text: annotation.text };
    this.setAnnotationAtDiv(assetContainer.asset.id, annotation.text);
    this.focusOutDIV();
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(`4D Portal`);
  }
}

export interface IAssetContainer {
  asset: Asset;
  annotation?: { rag: string, text: string };
  telemetry: Telemetry[];
  state: 'pending' | 'getting' | 'nodata' | 'hasdata';
}

<header>
  <app-toolbar size="small">
    <div class="right">
      <app-button label="Add comment" (click)="dialog.set('new-comment')" size="small" />
    </div>
  </app-toolbar>
</header>

<section class="mt-1 comments">
  @for (comment of issue().comments; track comment.id) {
    <div>
      <div class="flex">
        <div>
          {{ comment.createdByName }} <span class="created">{{ comment.createdAt | date: 'dd/MM/YYYY HH:MM' }}</span>
          @if (comment.createdBy === issue().createdBy) {
            <span class="author ml-1">issue author</span>
          }
        </div>
        <div class="flex-1 text-right">
          @if (comment.isMyComment) {
            <span class="dropdown"
              ><i class="mdi mdi-dots-vertical"></i>
              <div class="dropdown-content">
                <div class="can-hover" (click)="deleteComment(comment)">
                  <div><i class="mdi mdi-trash-can-outline"></i> Remove</div>
                  <div class="details">Remove your comment</div>
                </div>
              </div>
            </span>
          }
        </div>
      </div>
      <div class="mt-1">{{ comment.comment }}</div>
    </div>
  }
</section>
@switch (dialog()) {
  @case ('new-comment') {
    <app-dialog header="Add Comment" [dimensions]="{ width: 500, height: 180 }" (onClose)="dialog.set(null)">
      <section>
        <textarea class="fourd" [(ngModel)]="comment" rows="3"></textarea>
        <div class="text-right">
          <app-button (click)="addComment()" label="Add Comment" icon="mdi mdi-plus" />
        </div>
      </section>
    </app-dialog>
  }
}

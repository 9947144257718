<article>
  @if (!isLoading) {
    @if (!offline.length && !connectors().length) {
      <app-info-panel icon="check"> <i class="mdi mdi-check"></i> All assets online </app-info-panel>
    } @else {
      @if (asset()) {
        <app-asset-offline-landing [asset]="asset()" (onClose)="assetClick(null)"></app-asset-offline-landing>
      } @else {
        <div class="tabview-list">
          @if (can().issues) {
            <div [class.active]="tabIndex() === 0" (click)="tabIndex.set(0)"><i class="mdi mdi-forum"></i> Communication</div>
          }
          <div [class.active]="tabIndex() === 1" (click)="tabIndex.set(1)"><i class="mdi mdi-view-list"></i> Offline</div>
          <div></div>
        </div>
        <main class="mt-1">
          @switch (tabIndex()) {
            @case (0) {
              <app-offline-communication-landing></app-offline-communication-landing>
            }
            @case (1) {
              @if (dataTableConnectors()) {
                @if (dataTableConnectors().data?.length) {
                  <app-data-table [dataTable]="dataTableConnectors()"></app-data-table>
                }
              }
              @if (dataTableAssets()) {
                @if (dataTableAssets().data?.length) {
                  <app-data-table [dataTable]="dataTableAssets()" (onRowClick)="assetClick($event)"></app-data-table>
                }
              }
            }
          }
        </main>
      }
    }
  } @else {
    <span><i class="mdi mdi-loading mdi-spin-x2"></i></span>
  }
</article>

import { Component, EventEmitter, HostListener, Input, OnInit, Output, signal } from '@angular/core';
import { SiteFloorplanAsset } from 'app/classes/site-floorplan-asset';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-site-plan-shape',
  templateUrl: './site-plan-shape.component.html',
  styleUrls: ['./site-plan-shape.component.css'],
  standalone: false
})
export class SitePlanShapeComponent implements OnInit {

  @Input()
  shape: SiteFloorplanShape;

  @Input()
  fullscreen: boolean;

  assetTelemetry: AssetTelemetry[] = [];
  tabIndex = signal<number>(0);

  @Output()
  onBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    if (event.key === "Escape") {
      // User pressed escape on the modal
      this.onBack.emit();
    }
  }
  @HostListener('window:click', ['$event'])
  handleClick(event: MouseEvent) {
    console.log('CLICK', event);
  }
  constructor(private apiService: APIService) { }

  ngOnInit(): void {

  }

  navigateBack() {
    this.onBack.emit();
  }

}

export class AssetTelemetry {
  asset: SiteFloorplanAsset;
  telemetry: any[];
}

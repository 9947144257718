import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-fav-icon',
  standalone: false,
  templateUrl: './fav-icon.component.html',
  styleUrl: './fav-icon.component.css'
})
export class FavIconComponent {
  isSelected = input.required<boolean>();
  onFavChanged = output<boolean>();

  iconClick() {
    this.onFavChanged.emit(!!this.isSelected());
  }
}

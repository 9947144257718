import { Component, computed, input, OnInit, signal } from '@angular/core';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { APIService } from 'app/shared/api.service';
import { AssetService, IAssetControls } from 'app/shared/asset.service';

@Component({
  selector: 'app-site-plan-shape-control',
  standalone: false,
  templateUrl: './site-plan-shape-control.component.html',
  styleUrl: './site-plan-shape-control.component.css'
})
export class SitePlanShapeControlComponent implements OnInit {

  shape = input.required<SiteFloorplanShape>();
  assets = computed(() => this.shape().assets.filter(a => AssetService.ASSET_TYPES_CONTROL.includes(a.assetType_id)));
  controls = signal<IAssetControls>(null)
  canControl: boolean

  constructor(private assetService: AssetService, private apiService: APIService) {

  }

  ngOnInit(): void {
    this.canControl = this.apiService.hasOrgRole('operate_asset') || this.apiService.isAdmin();
    if (this.canControl) {
      this.getSettings();
    } else {
      this.controls.set({ assets: [] });
    }
  }

  getSettings() {
    this.assetService.getControlsForAssets(this.assets().map(a => a.id)).then(list => {


      console.log(list);

      this.controls.set(list);
    });
  }

  clickControl(asset, control) {
    if (!asset || !control) {
      return;
    }
    console.log(asset, control);
    this.apiService.toastSuccess(control.requesting + '..');
    this.assetService.sendActionToAsset(asset.asset_id, control.value).then(response => {
      this.apiService.toastSuccess('Request completed.');
      console.log(response);
    })
  }
}



<article class="content-container">
  <main>
    @if (asset()) {
      <h1 (click)="asset.set(null)" class="can-click"><i class="mdi mdi-chevron-left"></i> {{ asset().title }}</h1>
      <app-energy-asset-graph [asset]="asset()" />
    } @else {
      <h1 [routerLink]="['/energy']" class="can-click"><i class="mdi mdi-chevron-left"></i> Sites</h1>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Sensor</th>
          </tr>
        </thead>
        <tbody>
          @for (asset of assets(); track asset.id) {
            <tr (click)="assetClick(asset)">
              <td>{{ asset.title }}</td>
              <td>{{ asset.assetTypeTitle }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </main>
</article>

import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Org } from 'app/classes/org';
import { Site } from 'app/classes/site';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { timer } from 'rxjs';
import { WeatherService } from "app/shared/weather.service";
import { WeatherItem } from 'app/classes/weather-item';
import { StoreService } from 'app/shared/store.service';
import { Gateway } from 'app/classes/gateway';
import { MapboxService } from "app/shared/mapbox.service";
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import { RulePackage } from 'app/classes/rule-service/rule-package';

declare const google: any;


@Component({
  selector: 'app-org-map',
  templateUrl: './org-map.component.html',
  styleUrls: ['./org-map.component.css'],
  standalone: false
})
export class OrgMapComponent implements OnInit, OnDestroy {
  private timerSubscription: any;

  tabIndex
  _sites = signal<Site[]>(null);

  @Input()
  public set sites(v: Site[]) {
    this._sites.set(v);
    this.setMarkers();
  }
  public get sites(): Site[] {
    return this._sites();
  }

  weatherItems: WeatherItem[] = [];
  overlays: any[] = [];
  options: any;
  user: User;
  org: Org;
  infoWindow: any;
  mapBounds: any;
  orgs: Org[];
  orgSub: any;
  boundsSet: boolean;
  site: Site;
  gateway: Gateway;
  isLoadingWeather: boolean;
  currentZoom: number;
  defaults: any = { zoom: 7, hideSiteDetails: false, center: { lat: 0, lng: 0 }, markers: [] };
  map: any;
  orgId: number;
  mapMarkers = signal<mapboxgl.Marker[]>(null);
  filters: { alarms: boolean } = { alarms: false };
  rulePackages: RulePackage[];
  weatherOnMap: boolean = true;

  constructor(private apiService: APIService, private weatherService: WeatherService, private storeService: StoreService, private mapboxService: MapboxService) {

  }

  ngOnInit(): void {
    this.orgId = this.apiService.getUserOrg().id;
    this.options = {
      center: { lat: 53.7057599, lng: -1.912399 },
      zoom: 5
    };

    this.apiService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.org = user.org;
        if (this.org.shortTitle === '4DZA') {
          this.options = {
            center: { lat: -25.795390855062664, lng: 29.460312154541516 },
            zoom: 5
          };
        }
      }
    });

    this.infoWindow = new google.maps.InfoWindow();

    this.orgSub = this.storeService.orgs.subscribe(o => {
      this.orgs = o;
    });

    this.getTodaysWeather();
  }

  toggleWeather() {
    this.weatherOnMap = !this.weatherOnMap;

    this.setMarkers();
    if (this.weatherOnMap) {
      this.setWeatherMarkers();
    }
  }

  setWeatherMarkers() {
    const weatherMarkers = [];
    this.weatherItems.forEach(weather => {
      const { lat, lng, icon } = weather;
      const svgUrl = `https://4d-icons.s3.eu-west-2.amazonaws.com/weather/v2/${icon}.png`
      const svgIcon = document.createElement('div');
      svgIcon.style.width = '32px';    // Set desired width
      svgIcon.style.height = '32px';   // Set desired height
      svgIcon.style.backgroundImage = `url(${svgUrl})`;
      svgIcon.style.backgroundSize = 'contain';
      svgIcon.style.backgroundRepeat = 'no-repeat';
      svgIcon.style.backgroundPosition = 'center';
      const marker = new mapboxgl.Marker({
        element: svgIcon,
        anchor: 'bottom' // This positions the bottom of the SVG at the coordinate
      });
      marker.setLngLat({ lat, lng });


      const dt = moment(weather.pulledAt).format('DD/MM/YYYY  HH:mm');
      const snow = weather.snow ? `<div><label>snow</label> ${weather.snow}mm</div>` : '';
      const rain = weather.rain ? `<div><label>rain</label> ${weather.rain}mm</div>` : '';

      const content = `
      <div style="background-color:#f0f0f0;text-align:center;border-radius:6px;"><strong>${weather.description}</strong></div>
      <div><label>temperature</label> ${weather.temp}c</div>
      <div><label>pressure</label> ${weather.pressure}</div>
      <div><label>humidity</label> ${weather.humidity}</div>
      <div><label>feels like</label> ${weather.feelsLike}</div>
      <div><label>visibility</label> ${weather.visibility}</div>
      <div><label>wind</label> ${weather.wind.speed}m/s ${weather.wind.deg}deg ${weather.wind.gust || 'no '}gust</div>
      ${rain}
      ${snow}
      <div><label>Taken at</label> ${dt}</div>
      `;
      marker.setPopup(new mapboxgl.Popup().setHTML(content));

      weatherMarkers.push(marker);
    });
    this.mapMarkers.update(markers => {
      return [...weatherMarkers, ...markers];
    });
  }


  setMarkers() {
    const markersBottomLayer = [];
    const markersTopLayer = [];
    for (const site of this.sites) {
      if (!site.address.addressLat || !site.address.addressLong) {
        continue;
      }

      let colour = 'blue';

      if (this.filters.alarms) {
        colour = 'green';
        if (this.rulePackages.some(rp => rp.siteId === site.id)) {
          colour = 'red';
        }
      }

      const marker = new mapboxgl.Marker({ color: colour });

      marker.setLngLat({ lat: +site.address.addressLat, lng: +site.address.addressLong });
      const html = `
      <p><a href="/org/sites/${site.id}">${site.title}</a></p>
      <div>${site.address.addressTown}</div>
      <div>${site.address.addressPostcode}</div>`;
      marker.setPopup(new mapboxgl.Popup().setHTML(html));
      if (colour === 'red') {
        markersTopLayer.push(marker);
      } else {
        markersBottomLayer.push(marker);
      }
    }

    this.mapMarkers.set([...markersBottomLayer, ...markersTopLayer]);
  }

  getTodaysWeather() {
    if (this.isLoadingWeather) {
      return;
    }
    this.isLoadingWeather = true;

    const batch = [...new Set(this.sites.filter(s => s.metobsid).map(s => s.metobsid))];
    if (batch.length === 0) {
      return;
    }
    this.getWeather(batch, 'a=get-metobs-batch');
  }

  getWeather(batch, qs: string) {
    this.weatherService.post({ batch }, qs).then(w => {
      this.weatherItems = w;
      this.setWeatherMarkers();
    });
  }

  clickSitesInAlarm() {
    if (this.filters.alarms) {
      this.filters.alarms = false;
      this.setMarkers();
    } else {
      this.apiService.toastSuccess('Checking alarms...', null, 500);
      this.apiService.getRulePackages('alarms-conditions').then(rp => {
        this.rulePackages = rp;
        console.log(rp);
        this.filters.alarms = true;
        this.setMarkers();
      });
    }
  }

  startTimer() {
    if (!this.timerSubscription) {
      const dashboardTick = timer(1000);
      this.timerSubscription = dashboardTick.subscribe(t => {
        console.log('org dashboard tick', t);
        if (this.overlays[0]) {
          this.overlays[0].map.fitBounds(this.mapBounds);
        }
      });
    }
  }

  focusSite(site: Site) {
    site.weather = this.weatherItems.find(w => w.metobsid === site.metobsid);
    this.site = site;
  }

  focusWeather(w: WeatherItem) {
    const overlay: any = this.overlays.find((v) => v.metobsid === w.metobsid);

    if (overlay) {

      const sites = this._sites().filter(s => s.metobsid === w.metobsid).map(s => `<li>${s.title}</li>`).join('');

      const dt = moment(w.pulledAt).format('DD/MM/YYYY  HH:mm');
      const snow = w.snow ? `<div><label>snow</label> ${w.snow}mm</div>` : '';
      const rain = w.rain ? `<div><label>rain</label> ${w.rain}mm</div>` : '';

      const content = `
      <div style="background-color:#f0f0f0;text-align:center;border-radius:6px;"><strong>${w.description}</strong></div>
      <div><label>temperature</label> ${w.temp}c</div>
      <div><label>pressure</label> ${w.pressure}</div>
      <div><label>humidity</label> ${w.humidity}</div>
      <div><label>feels like</label> ${w.feelsLike}</div>
      <div><label>visibility</label> ${w.visibility}</div>
      <div><label>wind</label> ${w.wind.speed}m/s ${w.wind.deg}deg ${w.wind.gust || 'no '}gust</div>
      ${rain}
      ${snow}
      <div><label>Taken at</label> ${dt}</div>
      <div><ul>${sites}</ul></div>
      `;
      this.infoWindow.setContent('' + content + '');
      this.infoWindow.open(overlay.map, overlay);
    }
  }

  handleOverlayClick(event) {
    const isMarker = event.overlay.title != undefined;
    if (isMarker) {
      if (event.overlay.metobsid) {
        this.focusWeather(this.weatherItems.find(w => w.metobsid === event.overlay.metobsid));
      } else {
        const site: Site = this.sites.filter((v) => v.id == event.overlay.title.split(' ')[0].substring(1))[0];
        this.focusSite(site);
      }
    }
  }

  buildWeatherOverlay() {
    const bounds = new google.maps.LatLngBounds();
    const markers = [];

    this.weatherItems
      .forEach(w => {
        const myLatLng = new google.maps.LatLng(Number(w.lat), Number(w.lng));

        const icon = {
          url: w.iconUrl,
          scaledSize: new google.maps.Size(50, 50), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(32, 32), // anchor
          opacity: .9
        };

        const gdata = {
          position: { lat: Number(w.lat), lng: Number(w.lng) },
          title: w.description,
          metobsid: w.metobsid,
          icon,
          clickable: false,
          zIndex: 0
        };

        const marker = new google.maps.Marker(gdata);
        marker.setOpacity(.6);
        markers.push(marker);

        bounds.extend(myLatLng);
      });


    this.overlays = [...markers, ...this.overlays];
  }

  gatewaySelected(gateway: Gateway) {
    this.gateway = gateway;
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      console.log('unsubscribed from timer');
      this.timerSubscription.unsubscribe();
    }
    this.orgSub.unsubscribe();

  }
}

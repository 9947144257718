<div class="new-conditions flex gap-1">
  <div class="flex-1">
    <app-button (click)="newCondition('asset-to-value')"><i class="fa fa-thermometer-empty"></i> <i class="fa fa-long-arrow-right"> </i><i class="fa fa-keyboard-o"></i> Compare sensor to a value</app-button>
  </div>
  <div class="flex-1">
    <app-button (click)="newCondition('asset-to-asset')"> <i class="fa fa-thermometer-empty"></i> <i class="fa fa-long-arrow-right"></i><i class="fa fa-thermometer-empty"></i> Compare sensor to sensor </app-button>
  </div>
  <div class="flex-1">
    <app-button (click)="newCondition('asset-to-missing')"> <i class="fa fa-thermometer-empty"></i> <i class="fa fa-long-arrow-right"></i><i class="mdi mdi-access-point-network-off fa-fw"></i> Sensor not updated </app-button>
  </div>
  <!--
  <div class="col-xs-12 col-sm-6 col-md-4">
    <button [hidden]="true" (click)="newCondition('weather')" class="btn btn-block"><i class="fa fa-sun-o"></i> <i class="fa fa-long-arrow-right"></i> <i class="fa fa-keyboard-o"></i> Compare outside temperature</button>
  </div>-->
</div>

@if (showDialog()) {
  <app-dialog (onClose)="dialogClose($event)" [canScroll]="false" [dimensions]="{ width: 1020, height: 720 }">
    <app-rule-condition-new [index]="index" [modifyCondition]="_modifyCondition" [defaultSite]="defaultSite" [defaultGateway]="defaultGateway" (cancel)="newRuleConditionCancelled()" (submit)="newRuleConditionSubmitted($event)" (deleteCondition)="deleteCondition()" [selectionOnly]="action?.type === 'asset-to-asset'" [action]="action" />
  </app-dialog>
}

import { Component, computed, input, OnInit, output, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { ITrainingItem, User } from 'app/classes/user';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { APIService } from 'app/shared/api.service';
import { SiteService } from 'app/shared/site.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-org-user-details',
  templateUrl: './org-user-details.component.html',
  styleUrl: './org-user-details.component.css',
  standalone: false
})
export class OrgUserDetailsComponent implements OnInit {

  user = input.required<User>();
  userExtended = signal<User>(null);

  dirtyChange = output<IDirtyData>();
  sites = signal<Site[]>([]);
  training = signal<ITrainingItem[]>([]);
  userHasNotWatchedAllTraining = signal<boolean>(null);
  userDataForm: DataForm;
  isDirty = false;
  hasMarkedAllAsWatched: boolean;

  moduleList = signal<IModuleItem[]>([]);
  masterModuleList = signal<IModuleItem[]>([]);

  isAdmin = signal<boolean>(false);


  counts = computed(() => {
    let sitesChecked, sitesTotal;
    let modulesChecked, modulesTotal;
    let trainingChecked, trainingTotal;
    try {
      sitesChecked = this.sites().filter(site => site.checked).length;
      modulesChecked = this.masterModuleList().filter(module => module.checked).length;
      trainingChecked = this.userExtended().training.filter(training => training.watched).length;
      trainingTotal = this.userExtended().training.length;
      modulesTotal = this.masterModuleList().length;
      sitesTotal = this.sites().length;
    } catch (e) {

    }

    return { sites: { checked: sitesChecked, total: sitesTotal }, modules: { checked: modulesChecked, total: modulesTotal }, training: { watched: trainingChecked, total: trainingTotal } }
  });

  constructor(private userService: UserService, private siteService: SiteService, private apiService: APIService) {
    this.isAdmin.set(this.apiService.isAdmin());
  }

  ngOnInit(): void {

    // const operatingUser = this.apiService.getThisUser();
    const moduleAccess = Object.keys(this.apiService.moduleAccess);

    moduleAccess.forEach(value => {

      const module = this.apiService.MODULES[value];

      if (module) {
        this.masterModuleList().push({
          tooltip: this.apiService.MODULE_TOOLTIPS[value],
          label: value,
          value: module,
          checked: false
        });
      }
    });

    this.siteService.getSites().then(sites => {
      this.userService.getUser(this.user().id, 'extended=1').then(user => {
        user.notes = user.notes ?? '';

        user.modules.forEach(value => {
          // Ignore any deprecated modules the user may have
          if (this.apiService.MODULES[value]) {
            this.moduleList().push({
              tooltip: this.apiService.MODULE_TOOLTIPS[value] || '',
              label: value,
              value: this.apiService.MODULES[value]
            });
            if (!this.masterModuleList().some(m => m.label === value)) {
              // This user has access beyond the admin, add to master list
              this.masterModuleList().push({
                tooltip: this.apiService.MODULE_TOOLTIPS[value],
                label: value,
                value: this.apiService.MODULES[value]
              });
            }

            const module = this.masterModuleList().find(m => m.label === value);
            if (module) {
              module.checked = true;
            } else {
              console.log('cant match', value);
            }
          }
        });

        user.sites.forEach(s => {
          const site = sites.find(site => site.id === s.id);
          if (site) {
            site.checked = true
          }
        });

        this.userHasNotWatchedAllTraining.set(user.training.some(t => t.watched === false));
        this.sites.set(sites);
        this.training.set([...user.training.filter(t => t.watched)]);
        this.userExtended.set(user);

        this.userDataForm = new DataForm(
          {
            "config": {
              "labels": {
                "width": 110
              }
            },
            "items": [
              {
                "label": "Name",
                "id": "name",
                "value": this.userExtended().name,
                "type": "string"
              },
              {
                "label": "Notes",
                "id": "notes",
                "value": this.userExtended().notes,
                "type": "textarea",
                "options": { rows: 2 }
              }
            ],
            "id": this.userExtended().id
          }
        );

      });
    });
  }

  dataBlur() {
    this.checkDirty();
  }

  checkDirty() {

    let isDirty = false;

    if (!this.userExtended()?.sites || !this.sites().length) {
      return false;
    }

    const userSites = (this.userExtended().sites.map(s => s.id)).sort();
    const sites = (this.sites().filter(s => s.checked).map(s => s.id)).sort();

    const userModules = [...this.moduleList().map(m => m.label)].sort();
    const requestedModules = [...this.masterModuleList().filter(m => m.checked).map(m => m.label)].sort();


    if (userModules.join() !== requestedModules.join()) {
      isDirty = true;

      console.log(userModules, requestedModules);
      console.log('Dirty modules');
    }

    if (userSites.join() !== sites.join()) {
      console.log(userSites, sites);
      console.log('Dirty sites');
      isDirty = true;
    }


    if (this.training().map(t => t.title).join() !== this.userExtended().training.filter(t => t.watched).map(t => t.title).join()) {
      isDirty = true;
      console.log('Dirty training');
    }

    if (this.userDataForm.getValue('name') !== this.user().name) {
      isDirty = true;
      console.log('dirty name');
    }

    console.log(this.userDataForm.getValue('notes'), this.userExtended().notes);

    // Extended notes may be null
    if (this.userDataForm.getValue('notes') !== (this.userExtended().notes ?? '')) {
      isDirty = true;
    }

    this.isDirty = isDirty;

    if (isDirty) {
      console.log('onDataChange()');
      const modules = this.masterModuleList().filter(m => m.checked).map(m => m.label);
      const changes: IDirtyData = { sites, modules };
      if (this.hasMarkedAllAsWatched) {
        changes.training = this.userExtended().training.map(t => t.id);
      }
      if (this.userDataForm.getValue('name') !== this.userExtended().name) {
        changes.name = <string>this.userDataForm.getValue('name');
      }
      if (this.userDataForm.getValue('notes') !== this.userExtended().notes) {
        changes.notes = <string>this.userDataForm.getValue('notes');
      }
      console.log('CHANGES', changes);
      this.dirtyChange.emit(changes);
    } else {
      this.dirtyChange.emit(null);
    }
  }

  markAllAsWatched() {
    this.hasMarkedAllAsWatched = true;

    this.userExtended.update(user => {
      user.training.forEach(t => t.watched = true);

      return new User(user);
    });

    this.checkDirty();
  }

  toggleSite(site: Site) {
    this.sites.update(sites => {
      site.checked = !site.checked;

      return [...sites];
    });
    this.checkDirty();
  }

  toggleModule(module: IModuleItem) {
    this.masterModuleList.update(modules => {
      module.checked = !module.checked;

      return [...modules];
    });
    this.checkDirty();
  }

  toggleSites() {
    this.sites.update(sites => {
      sites.forEach(s => s.checked = !s.checked);

      return [...sites];
    });

    this.checkDirty();
  }
}

export interface IDirtyData {
  // Site access
  sites: number[];
  // Module access
  modules: string[];
  // Trainig modules to be marked as watched
  training?: number[];
  // The changed name
  name?: string;
  // the changed notes
  notes?: string;
}


export interface IModuleItem {
  label: string;
  value: string;
  tooltip?: string;
  checked?: boolean;
}

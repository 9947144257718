<div class="preloader">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 50 200 100">
      <style type="text/css">
        .fill {
          fill: #dbdbdb;
          stroke: #dbdbdb;
          stroke-width: 15;
        }
      </style>
      <circle class="fill" r="15" cx="40" cy="100">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
      </circle>
      <circle class="fill" r="15" cx="100" cy="100">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
      </circle>
      <circle class="fill" r="15" cx="160" cy="100">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
      </circle>
    </svg>
  </div>
</div>

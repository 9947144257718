import { Component, OnDestroy, OnInit, effect, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';
import { Org } from 'app/classes/org';
import { IDataTableSortColumn } from 'app/layout/data-table/data-table/data-table.component';
import { APIService } from 'app/shared/api.service';
import { WindowService } from 'app/shared/window.service';

@Component({
  selector: 'app-org-switch',
  templateUrl: './org-switch.component.html',
  styleUrl: './org-switch.component.css',
  standalone: false
})
export class OrgSwitchComponent implements OnInit, OnDestroy {
  readonly VERSION = 2;
  orgsIn = input.required<Org[]>();
  orgs: Org[] = [];
  orgSwaps: Org[] = [];
  LOCALSTORAGE_ORGS_SWAP = 'orgs:swap';
  showInactive = false;
  showId = false;
  isWorking = signal<boolean>(false);
  switchToOrg: Org;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';

  hasSeenVersion: number;
  dataTable: DataTable;

  dataTableConfigDesktop: IDataClassConfig = {
    "id": { "valueType": "number", "title": "ID", "align": "right", "width": 50 },
    "logo": { "valueType": "image", "title": "", "align": "left", "dataFormat": "logo", "width": 40 },
    "title": { "valueType": "string", "title": "Org", "align": "left" },
    "shortTitle": { "valueType": "string", "title": "Short title", "align": "left" },
    "type": { "valueType": "string", "title": "Type", "align": "left", "width": 90 },
    "addressCounty": { "valueType": "string", "title": "County", "align": "left", width: 180 },
    "addressPostcode": { "valueType": "string", "title": "Postcode", "align": "left" },
    "createdAt": { "valueType": "date", "dataFormat": "date", "title": "Created", "align": "right", width: 90 },
    "isActive": { "valueType": "boolean", "title": "Active", "isHidden": true, "align": "center" },
  };

  dataTableConfigMobile: IDataClassConfig = {
    "id": { "valueType": "number", "title": "ID", "align": "right", "width": 50 },
    "logo": { "valueType": "image", "title": "", "align": "left", "dataFormat": "logo", "width": 40 },
    "title": { "valueType": "string", "title": "Org", "align": "left" },
    "shortTitle": { "valueType": "string", "title": "Short title", "align": "left" },
    "address1": { "valueType": "string", "title": "Address", "align": "left" }
  };

  filtered: {
    lists: { orgs: Org[] },
    text: { orgs: string },
    counts: { orgs: number }

  } = {
      lists: { orgs: [] },
      text: { orgs: '' },
      counts: { orgs: null }
    };
  view = signal<'click-to-switch'>('click-to-switch');
  isMobile = false;

  constructor(private apiService: APIService, private router: Router, private windowService: WindowService) {
    const orgSwaps = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_ORGS_SWAP) || '{}');

    this.showInactive = orgSwaps.showInactive;
    this.showId = orgSwaps.showId ?? false;
    this.sortColumn = orgSwaps?.sortColumn ?? false;
    this.sortOrder = orgSwaps?.sortOrder ?? false;
    this.hasSeenVersion = orgSwaps.version ?? 0;

    effect(() => {
      this.orgs = this.orgsIn();
      //this.switchToOrg = this.orgs[0];
      this.orgSwaps = this.orgs.filter(o => o.swapCount)
        .sort((a, b) => a.swapCount < b.swapCount ? 1 : -1)
        .sort((a, b) => a.swapUpdatedAt < b.swapUpdatedAt ? 1 : -1);
      this.applyOrgFilter();

    });
    this.isMobile = windowService.isMobile();
  }

  ngOnInit() {
  }

  sortChanged(ev: IDataTableSortColumn) {
    this.sortColumn = ev.attribute;
    this.sortOrder = ev.sortDirection;
    this.saveLocalStorage();
  }

  toggleInactiveOrgs() {
    this.showInactive = !this.showInactive;
    this.saveLocalStorage();
  }

  saveLocalStorage() {
    localStorage.setItem(this.LOCALSTORAGE_ORGS_SWAP, JSON.stringify({ version: this.hasSeenVersion, showInactive: this.showInactive, showId: this.showId, sortColumn: this.sortColumn, sortOrder: this.sortOrder, view: this.view() }));
  }

  sortOrgBy(property: string) {
    this.orgs.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
  }

  clickRow(org: Org) {
    if (this.isMobile) {
      this.switchOrg(org);
    }
  }

  switchOrg(org: Org) {
    this.switchToOrg = org;

    if (this.view() === 'click-to-switch') {
      this.isWorking.set(true);
      this.navigateToOrg();
    } else {

    }
  }

  navigateToOrg() {
    window.scroll(0, 0);

    this.apiService.switchOrg(this.switchToOrg).then((result) => {
      this.isWorking.set(false);
      if (result) {

        this.router.navigate(['/login']);
      }
    });
  }

  toggleOrgId() {
    this.showId = !this.showId;
    this.saveLocalStorage();
  }

  dismissVersionUpdate() {
    this.hasSeenVersion = this.VERSION;
  }

  applyOrgFilter() {
    this.dataTable = null;
    if (this.filtered.text.orgs === '') {
      this.filtered.lists.orgs = this.orgs;
      this.filtered.counts.orgs = this.orgs.length;
    } else {
      const searchFor = this.filtered.text.orgs;
      this.filtered.lists.orgs = this.orgs.filter(s => {
        if (s.title.toLowerCase().includes(searchFor)) return true;
        if (s.shortTitle.toLowerCase().includes(searchFor)) return true;
        if (s.id === +searchFor) return true;
        if (s.address?.address1.toLowerCase().includes(searchFor)) return true;
        if (s.address?.addressCounty.toLowerCase().includes(searchFor)) return true;
        if (s.address?.addressTown.toLowerCase().includes(searchFor)) return true;
        if (s.address?.addressPostcode.toLowerCase().includes(searchFor)) return true;
      });

      this.filtered.counts.orgs = this.filtered.lists.orgs.length;
    }
    const config = this.isMobile ? this.dataTableConfigMobile : this.dataTableConfigDesktop;
    const data = { data: this.filtered.lists.orgs, sortedColumns: [{ attribute: this.sortColumn, direction: this.sortOrder }] };
    this.dataTable = new DataTable(data, config);
  }

  searchChanged(section: 'orgs', text: string) {
    console.log(text);
    this.filtered.text[section] = text.toLowerCase().trim();
    this.saveLocalStorage();
    this.applyOrgFilter();
  }

  ngOnDestroy(): void {

    this.saveLocalStorage();
  }
}

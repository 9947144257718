import { Injectable } from '@angular/core';
import { RulePackage } from "../classes/rule-service/rule-package";
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root'
})
export class RulepackageService {

  static readonly API_URL = 'https://zbmuhspgkh.execute-api.eu-west-2.amazonaws.com/v1';

  constructor(private sessionService: SessionService) { }

  update(rulepackage: RulePackage) {
    const response = fetch(RulepackageService.API_URL + '/update', {
      headers: {
        'Authorization': this.sessionService.getToken()
      }, method: 'POST', body: JSON.stringify(rulepackage.serialise())
    }).then(res => res.json());

    return response;
  }

  // Dont touch existing V1 rules, patch in V2 extras (action etc)
  patchExisting(rule: RulePackage, data: any, v1response: any) {
    const response = fetch(RulepackageService.API_URL + '/update?a=patch', {
      headers: {
        'Authorization': this.sessionService.getToken()
      }, method: 'POST', body: JSON.stringify({ rule: rule.serialise(), data, v1response })
    }).then(res => res.json());

    return response;
  }
}

@defer (when hasLoaded()) {
  <article class="content-container">
    <main>
      <section class="settings">
        @if (isEnabled) {
          <app-info-panel state="success">Issue tracking is enabled</app-info-panel>

          <div class="data text-center">
            <p>You can go back to any version 2 building review in the past, and add it as an issue.</p>
            <p>Select the "Track these issues" button in the building review to add it.</p>
          </div>

          <div class="data text-center">
            You can disable automatic generation of issues from reviews at any time, or always leave disabled and choose the issues manually from the review.
            <div class="mt-1">
              <app-button [label]="autoModeisEnabled ? 'Issues are automatically generated from reviews' : 'Issues are not automatically generated, and will need to be selected from reviews manually'" [colour]="autoModeisEnabled ? 'green' : 'black'" (click)="autoModeisEnabled = !autoModeisEnabled" />
            </div>
          </div>

          <div class="data">
            <p class="text-center">When automatically adding issues from reviews, annotations with the RAG values below are added as issues.</p>
            <div class="flex text-center rag-buttons">
              <div [class.is-not-selected]="ragSelected() !== 'green'" [class.is-selected]="ragSelected() === 'green'">
                <app-button colour="green" label="Green" (click)="setRAG('green')" [isMuted]="ragSelected() !== 'green'" [selected]="ragSelected() === 'green'" />
                <i class="mdi mdi-arrow-right"></i>
              </div>
              <div [class.is-not-selected]="ragSelected() === 'red'" [class.is-selected]="ragSelected() === 'amber'" [class.is-mid-selected]="ragSelected() === 'green'">
                <app-button label="Amber" colour="amber" (click)="setRAG('amber')" [isMuted]="ragSelected() === 'red'" [selected]="ragSelected() === 'amber'" />
                <i class="mdi mdi-arrow-right"></i>
              </div>
              <div class="red-rag" [class.is-selected]="ragSelected() === 'red'">
                <app-button label="Red" colour="red" (click)="setRAG('red')" [selected]="ragSelected() === 'red'" />
              </div>
            </div>
            <p class="text-center">
              <i>
                @switch (ragSelected()) {
                  @case ('green') {
                    Every asset in a review is added as an issue.
                  }
                  @case ('amber') {
                    <span class="amber">Amber</span> and <span class="red">Red</span> assets in a review are added as issues.
                  }
                  @case ('red') {
                    <span class="red">Red</span> assets in a review are added as issues.
                  }
                }
              </i>
            </p>
          </div>

          <div class="data">
            <p class="text-center">You can disable issue tracking at any time.</p>
            <ul style="margin: 0 auto; width: 400px">
              <li>Hides the module for non admin users</li>
              <li>Automatic generation of issues will be suspended</li>
              <li>Leaves data intact</li>
            </ul>
            <p class="text-center">
              <app-button icon="mdi mdi-clipboard-text-off" label="Disable issue tracking" (click)="disable()"></app-button>
            </p>
          </div>
        } @else {
          <app-info-panel state="warning">Issue tracking not enabled</app-info-panel>

          <div class="data">
            <p>
              Issue tracking has not been enabled for
              <span class="text-emphasise">{{ org().title }}.</span>
            </p>
            <p>After enabling issue tracking, the system starts tracking new building reviews and adds issue tracking records for all annotations on new reviews.</p>
            <p>If this has been enabled for the first time, you will will not see any existing building reviews by default.</p>
            <p class="text-center">
              <app-button icon="mdi mdi-text-box-check-outline" label="Enable issue tracking" (click)="enable()"></app-button>
            </p>
          </div>
        }
      </section>
    </main>
  </article>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

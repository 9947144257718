<main>
  @for (asset of assets; track asset) {
    <section>
      <div class="rule-title" [class.one-row]="!asset.right">
        <div>
          @if (asset.left) {
            <div>
              <span class="asset-icon">
                <app-asset-icon [asset]="asset.left" />
              </span>
              {{ asset.left.title }}
              <!--<span class="live-value" pTooltip="Current sensor value">{{asset.left.value}}</span>-->
            </div>
          }
          @if (asset.right) {
            <div>
              <span class="asset-icon">
                <app-asset-icon [asset]="asset.right" />
              </span>
              {{ asset.right.title }}
              <!--<span class="live-value" pTooltip="Current sensor value">{{asset.right.value}}</span>-->
            </div>
          }
        </div>
      </div>

      <div class="rule-operand">
        <div>
          <span>{{ asset.operand }}</span>
        </div>
      </div>

      <div class="rule-value">
        <div>
          <span pTooltip="Rule value" tooltipPosition="left">{{ asset.rightValue }}</span>
          <!--<span class="live-value" pTooltip="Current value for the sensor">({{liveAsset?.value}})</span>
				<p>Updated {{liveAsset?.updatedAt | amTimeAgo}} <span *ngIf="liveAsset">(No asset)</span></p>-->
        </div>
      </div>
    </section>
  }
</main>

<article class="content-container">
  <app-page-title label="Test Rule" [canGoBack]="true" [routerLink]="['..']" />

  <div>
    <p>These tests do not effect any data and can be run safely, this routine currently returns JSON data.</p>
  </div>
  <section class="mt-1">
    <app-button label="Test against current value" (click)="runTest()" />
  </section>

  <section class="results">
    @if (response()) {
      <h1>Response</h1>

      <p>
        @if (response().inAlert === 'Y') {
          <span class="red"> IN ALERT </span>
        } @else {
          <span class="green">NOT IN ALERT</span>
        }
      </p>

      <pre>{{ response() | json }}</pre>
    }
  </section>
</article>

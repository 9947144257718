import { Component, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Asset } from 'app/classes/asset';
import { BuildingReview } from 'app/classes/building-review';
import { IAssetContainer } from 'app/insights/reviews/insights-review-item/insights-review-item.component';
import { BuildingsService } from 'app/shared/buildings.service';

@Component({
  selector: 'app-automation-review',
  templateUrl: './automation-review.component.html',
  styleUrl: './automation-review.component.css',
  standalone: false
})
export class AutomationReviewComponent {
  review = signal<BuildingReview>(null);
  assets = signal<IAssetContainer[]>(null);
  start: string;
  token: string;
  hasFailed = false;
  assetCount: number;
  assetsLoaded = signal<number>(0);
  isLoadingData = true;

  constructor(private titleService: Title, private activatedRoute: ActivatedRoute, private buildingService: BuildingsService) {
    this.token = activatedRoute.snapshot.params.tokenid;

    // Must have these inputs
    if (!this.token) {
      this.hasFailed = true;
    } else {
      console.log(`Recievied parameters`, this.token);
      this.titleService.setTitle(`please wait`);
      this.buildingService.getReviewForAutomation(this.token).then(review => {
        const buildingReview = new BuildingReview(review);

        this.assets.set(review.assets.map(a => { return { asset: new Asset({ id: a.id, title: a.title, assetType_id: a.assetType_id }), telemetry: null, state: 'pending', annotation: { rag: 'green', text: '' } } }));
        this.review.set(buildingReview);

        review.annotations.sort((a, b) => a.rag === 'red' ? -2 : a.rag === 'amber' ? -1 : 1);

        review.annotations.forEach(a => {
          const asset = this.assets().find(asset => asset.asset.id === a.asset_id);
          if (asset) {
            asset.annotation = { rag: a.rag, text: a.annotation };
          }
        });

        this.initialiseReview();
      });
    }
  }

  getDates() {
    const start = this.review().review.dates.start;
    const end = this.review().review.dates.end;

    return { start, end };
  }

  initialiseReview() {
    this.assetCount = this.assets.length;
    this.assetsLoaded.set(0);
    this.getTelemetry();
  }

  getTelemetry() {
    const getAsset = this.assets().find(a => a.state === 'pending');

    if (!getAsset) {
      this.assetsLoaded.set(this.assetCount);
      this.isLoadingData = false;
      this.titleService.setTitle(`u_10_b_${this.review().building.id}_d_${+this.review().review.dates.start}_review.pdf`);
      return;
    }

    this.buildingService.getReviewTelemetryForAutomation(this.token, getAsset.asset.id, this.review().review.dates.start, this.review().review.dates.end).then((response: any) => {
      getAsset.state = 'hasdata';
      this.assetsLoaded.set(this.assetsLoaded() + 1);


      this.assets.update(assets => {
        const index = assets.findIndex(a => a.asset.id === getAsset.asset.id);
        assets[index] = { ...getAsset, telemetry: response.telemetry };

        return assets;
      });

      setTimeout(() => {
        this.getTelemetry();
      }, 10);
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { APIService } from 'app/shared/api.service';
import { DateService } from 'app/shared/date.service';
import { RuleService } from 'app/shared/rule.service';
import { axisRight } from 'd3';

@Component({
  selector: 'app-rulesv3-item-conditions',
  templateUrl: './rulesv3-item-conditions.component.html',
  styleUrls: ['./rulesv3-item-conditions.component.css'],
  standalone: false
})
export class Rulesv3ItemConditionsComponent implements OnInit {

  @Input()
  rule: RulePackage;

  assets: { left: Asset, right: Asset, rightValue: string, operand: string }[] = [];

  liveAsset: Asset;

  constructor(private ruleService: RuleService, private apiService: APIService) { }

  ngOnInit(): void {
    for (let index = 0; index < this.rule.conditions.length; index++) {
      const condition = this.rule.conditions[index];

      const asset = { left: null, right: null, rightValue: null, operand: null };

      if (condition.leftAsset.asset.id) {
        asset.left = condition.leftAsset.asset;
        asset.left.value = condition.leftAsset.computedValue;
      } else {
        asset.left = null;
      }

      if (condition.rightAsset.asset.id) {
        asset.right = condition.rightAsset.asset;
        asset.right.value = condition.rightAsset.computedValue;
      } else {
        asset.right = null;
      }

      if (condition.action === 'asset-to-missing') {
        asset.operand = 'not updated for';
        asset.rightValue = DateService.minutesToEnglish(condition.rightAsset.value);
      } else {
        asset.operand = this.ruleService.operatorAsWords(condition.operator);
        asset.rightValue = condition.rightAsset.value;
      }
      this.getAsset(asset);
      this.assets.push(asset);
    }
  }

  getAsset(asset) {
    const id = asset?.left?.id;
    if (id) {
      this.apiService.getAsset(id).then(a => this.liveAsset = a);
    }
  }

}

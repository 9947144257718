import { Component, EventEmitter, Input, OnInit, Output, model } from '@angular/core';
import { Gateway } from 'app/classes/gateway';

@Component({
  selector: 'app-report-gateway-list',
  templateUrl: './report-gateway-list.component.html',
  styleUrls: ['./report-gateway-list.component.css'],
  standalone: false
})
export class ReportGatewayListComponent implements OnInit {
  favs: any = {};
  selectedGateway: any;
  gateways = model.required<any>();
  orgs: string[] = [];
  orgCount: number;

  @Input()
  show: 'all' | 'favourites' = 'all';

  @Output()
  gatewayToggle: EventEmitter<Gateway> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.getFavs();
    this.applyFavs();
    const orgs = new Set(this.gateways().map(obj => obj.org.shortTitle));
    this.orgCount = orgs.size;
  }

  getFavs() {
    try {
      const favs = JSON.parse(localStorage.getItem('gateways:favs') || '{}');
      this.favs = favs;
    } catch (e) {
      localStorage.removeItem('gateways:favs');
    }
  }

  applyFavs() {
    this.gateways.update(gateways => {
      gateways.forEach(gateway => gateway.isFav = !!this.favs[gateway.id]);

      return gateways;
    });
  }

  clickFav(gateway: Gateway) {
    if (this.favs[gateway.id]) {
      delete this.favs[gateway.id];
    } else {
      this.favs[gateway.id] = 1;
    }
    localStorage.setItem('gateways:favs', JSON.stringify(this.favs));
    this.applyFavs();
  }

  selectGateway(gateway: Gateway) {
    this.gateways.update((gateways) => {
      gateways.filter(item => item.id === gateway.id).map(item => item.selected = !item.selected);

      return gateways;
    });
    this.selectedGateway = gateway;
    this.gatewayToggle.emit(gateway);
  }
}

import { Component, model, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { IIssueTrackingComment, IssueTrackingService, ITrackingIssue } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-issue-tracking-comments',
  standalone: false,
  templateUrl: './issue-tracking-comments.component.html',
  styleUrl: './issue-tracking-comments.component.css'
})
export class IssueTrackingCommentsComponent {
  issue = model.required<ITrackingIssue>();
  comment = '';
  dialog = signal<'new-comment'>(null);


  constructor(private buildingReviewService: BuildingsService, private issueTracking: IssueTrackingService, private assetService: AssetService, private apiService: APIService, private route: ActivatedRoute) {

  }

  addComment() {
    if (this.comment.trim().length < 1) {
      this.dialog.set(null);
      return;
    }

    this.apiService.toastWarn('Adding comment...', null, 900);
    this.issueTracking.addComment(this.issue().id, this.comment).then(response => {

      this.getComments();
      this.apiService.toastWarn('Comment has been added', null, 500);
      this.dialog.set(null);
    });
  }

  getComments() {
    this.issueTracking.getIssue(this.issue().id).then(response => {
      this.issue.update(issue => {
        issue.comments = response.issue.comments;

        return issue;
      })
    });
  }

  deleteComment(comment: IIssueTrackingComment) {
    this.apiService.toastSuccess('Marking comment as deleted...', null, 1000);
    this.issueTracking.deleteComment(comment.id, comment.comment).then(d => {
      this.getComments();
    });
  }
}

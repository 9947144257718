import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, signal } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService, PostRuleInterface } from '../../../shared/api.service';
import { RulePackage } from '../../../classes/rule-service/rule-package';
import { RuleTimeRange } from "../../../classes/rule-service/rule-hours";
import { RuleNotification } from "../../../classes/rule-service/rule-notification";
import { User } from "../../../classes/user";
import { StoreService } from "../../../shared/store.service";
import { RuleCondition } from "../../../classes/rule-service/rule-condition";
import { Site } from "../../../classes/site";
import { Gateway } from "../../../classes/gateway";
import { Asset } from "../../../classes/asset";
import { ConfirmationService } from "primeng/api";
import { RuleAction } from '../../../classes/rule-service/rule-action';
import { Subscription } from 'rxjs';
import { RulePackageHelp } from "./rule-engine-v2-new.help";
import { UserGroupService } from 'app/shared/user-group.service';
import { UserGroup } from 'app/classes/user-group';
import { filter } from 'rxjs/operators';
import { RulepackageService } from "app/shared/rulepackage.service";
import { IRuleConditionPickerConfig, RuleConditionPickerComponent } from 'app/layout/rule-condition-picker/rule-condition-picker.component';
import { DateService } from 'app/shared/date.service';

@Component({
  selector: 'app-rule-engine-v2-new',
  templateUrl: './rule-engine-v2-new.component.html',
  styleUrls: ['./rule-engine-v2-new.component.css'],
  standalone: false
})
export class RuleEngineV2NewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('titleReference')
  titleReference: ElementRef;

  showDialog = signal<'delete' | 'notification'>(null);
  ruleId: number;
  rulePackage: RulePackage;
  details: string = 'New Rule';
  action: string;
  subscription: any;
  ruleConditions: RuleCondition[];
  defaultSite: Site;
  defaultGateway: Gateway;
  ruleHasRestrictedHours: boolean;
  ruleHasNotifications: boolean;
  addingNotification: string; // email | ifttt
  addingAction: string; // switch
  orgUsers: User[];
  startTimeAt: string;
  endTimeAt: string;
  selectedEmail: string;
  // The assumed site from the conditions
  siteId: number;
  ruleInWords: string;
  email: string; // Add a notification by email
  // Set to a ruleCondition to start modifying
  modifyCondition: RuleCondition;
  modifyAction: RuleAction;
  dialogIcon: string;
  dialogShow: boolean;

  dialogHeader: string;
  userSubscription: Subscription;
  user: User;
  helpShow: string;
  pleaseWait: boolean = false;
  help: RulePackageHelp = new RulePackageHelp();
  siteUserGroups: UserGroup[];
  selectedAsset: Asset;
  isShowingPopupSensorData: boolean;
  issues: {
    title: boolean,
    conditions: boolean;
    triggerAfter: boolean;
  } = {
      title: false,
      conditions: false,
      triggerAfter: false
    };

  integration: any;
  can: { integration: boolean, ruleControl: boolean } = { integration: false, ruleControl: false };
  hasRightSensors: boolean;
  ruleConditionConfig: IRuleConditionPickerConfig;

  constructor(private rulepackageService: RulepackageService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, public apiService: APIService, private confirmationService: ConfirmationService, private userGroupService: UserGroupService, public dateService: DateService) {
    this.userSubscription = apiService.user.subscribe(user => {
      this.user = user;
      if (user) {
        this.can.integration = (user.role === 'admin' || user.orgRoles.join(',').indexOf('rule_elogbooks') !== -1);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  minutesToEnglish(minutes: number) {
    return DateService.minutesToEnglish(minutes);
  }

  showHelp(field: string) {
    this.helpShow = field;
  }

  ngOnInit() {
    const payload = {
      oid: this.apiService.getUserOrg().id,
      t: 'integrations',
      action: 'get-integrator-consumer',
      id: 'elogbooks'
    };
    this.apiService.postQuery(payload).then(i => {
      if (i) {
        this.integration = i;
      }
    });

    this.route.params.subscribe((params: Params) => {
      this.ruleId = params['ruleid'];
      if (this.ruleId) {
        this.apiService.getRulePackage(this.ruleId)
          .then(rulePackage => {
            this.details = rulePackage.title;
            this.rulePackage = rulePackage;
            this.calculateSite();

            if (this.rulePackage.ruleTimeRanges.length === 0) {
              this.rulePackage.ruleTimeRanges.push(new RuleTimeRange());
            }
            this.ruleHasRestrictedHours = !!(this.rulePackage.ruleTimeRanges[0].dayOfWeeks.length);
            this.startTimeAt = this.rulePackage.ruleTimeRanges[0].startTimeAt;
            this.endTimeAt = this.rulePackage.ruleTimeRanges[0].endTimeAt;

            this.setWords();
          });
      } else {
        this.rulePackage = new RulePackage();
        this.rulePackage.ruleTimeRanges.push(new RuleTimeRange());
      }
    });

    this.subscription = this.storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'rules'))
      .subscribe(state => {
        if (this.action) {
          this.action = null;
          this.details = 'New Rule';

          return;
        }
        // Set to handled
        state.state = false;
        this.storeService.setSectionBannerBack(state);

        this.router.navigate(['..'], { relativeTo: this.route });
      });
    this.can.ruleControl = this.apiService.hasOrgRole('rule_control');
  }

  calculateSite() {
    let originalSiteId = this.siteId;

    this.hasRightSensors = this.rulePackage.conditions.some(c => c.rightAsset?.asset?.id);

    this.rulePackage.conditions.forEach(condition => {
      this.siteId = condition.leftAsset.asset.gateway.site.id;
    });
    if (this.siteId && this.siteId !== originalSiteId) {
      this.userGroupService.get('sites', this.siteId).then(users => this.siteUserGroups = users);
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      // Focus on the title for new rules
      if (!this.ruleId) {
        try {
          this.titleReference?.nativeElement?.focus();
        } catch {

        }
      }
    }, 800);
  }

  newRuleConditionCancelled() {
    this.action = null;
    this.modifyCondition = null;
  }

  deleteCondition(index: number) {
    console.log('delete condition', index);
    this.removeCondition(index);
    this.modifyCondition = null;
    this.action = null;
  }

  newActionCancelled() {
    this.action = null;
    this.modifyAction = null;
  }

  newRuleActionSubmitted(ruleAction: RuleAction) {
    this.action = null;
    // Modifying a rulecondition
    if (this.modifyAction) {
      for (let index = 0; index < this.rulePackage.actions.length; index++) {
        let element = this.rulePackage.actions[index];
        if (element.id === ruleAction.id) {
          this.rulePackage.actions[index] = ruleAction;
          this.modifyAction = null;
        }
      }
      this.calculateSite();
      return;
    }

    if (!this.rulePackage.actions) {
      // Initialise the rule actions array if first condition
      this.rulePackage.actions = [];
    }
    this.rulePackage.actions.push(ruleAction);

    this.defaultGateway = ruleAction.asset.gateway;
    this.defaultSite = ruleAction.asset.gateway.site;
    this.calculateSite();
  }

  newRuleConditionSubmitted(ruleCondition: RuleCondition) {
    this.action = null;

    if (!ruleCondition) {
      return;
    }
    const { id, uuid } = ruleCondition;

    const conditionIndex = this.rulePackage.conditions.findIndex(r => r.uuid === uuid || (id && r.id === id));

    if (conditionIndex >= 0) {
      this.rulePackage.conditions[conditionIndex] = ruleCondition;
      this.calculateSite();
      this.setWords();
      return;
    }

    this.rulePackage.conditions = this.rulePackage.conditions || [];
    this.rulePackage.conditions.push(ruleCondition);
    this.defaultGateway = ruleCondition.leftAsset.asset.gateway;
    this.defaultSite = ruleCondition.leftAsset.asset.gateway.site;
    this.calculateSite();
    this.setWords();
  }

  changeRuleCondition(ruleCondition: RuleCondition, index?: number) {
    this.ruleConditionConfig = {
      gateway: this.defaultGateway,
      site: this.defaultSite,
      ruleCondition,
      index,
      action: ruleCondition.action
    };

    this.calculateSite();
  }

  toggleDow(dow: number, shoudToggle?: boolean) {
    if (shoudToggle) {
      this.rulePackage.ruleTimeRanges[0].invertDOW(dow);
    }
    this.setWords();
  }

  setWords() {
    let words: string = 'This rule ';
    let r = this.rulePackage;

    if (r.ruleTimeRanges.length) {
      let names = r.ruleTimeRanges[0].getDOWAsNames();
      let endsAt = this.endTimeAt || 'end of day';
      let startsAt = this.startTimeAt || 'start of day';
      if (!this.startTimeAt && !this.endTimeAt && names === '') {
        words += `runs 24 hours a day, 7 days a week.`;
      } else {
        words += `is restricted to ${names} at ${startsAt} until ${endsAt} `;
      }

    } else {
      words = 'is active 7 days a week, 24 hours a day.';
    }

    this.ruleInWords = words;
  }


  submitRule(makeCopy: boolean = false) {
    /*
     * Check for issues and flag to user
     */

    this.issues.title = !this.rulePackage.title;
    this.issues.conditions = !this.rulePackage.conditions.length;
    this.issues.triggerAfter = (this.rulePackage.triggerAfter !== undefined && isNaN(this.rulePackage.triggerAfter))

    if (Object.keys(this.issues).some(a => this.issues[a])) {
      this.apiService.toastWarn('Please enter missing data', '');
      return;
    }

    const id = this.rulePackage.id;
    if (this.startTimeAt) {
      this.rulePackage.ruleTimeRanges[0].startTimeAt = this.startTimeAt;
    }
    if (this.endTimeAt) {
      this.rulePackage.ruleTimeRanges[0].endTimeAt = this.endTimeAt;
    }
    this.pleaseWait = true;
    if (this.rulePackage.ruleTimeRanges.length) {
      // Backend uses the dow array
      this.rulePackage.ruleTimeRanges[0].convertBooleanToDow();
    } else {
      // There are no days of weeks selected, clear down the start/end time
      this.rulePackage.ruleTimeRanges[0].endTimeAt = null;
      this.rulePackage.ruleTimeRanges[0].startTimeAt = null;
    }


    this.rulePackage.gatewayId = this.rulePackage.conditions[0].leftAsset.asset.gateway.id;

    if (id && !makeCopy) {
      const data: PostRuleInterface = {
        title: this.rulePackage.title,
        triggerAfter: this.rulePackage.triggerAfter || 0,
        notifications: this.rulePackage.notifications,
        conditions: this.rulePackage.conditions.map(c => c.serialise()),
        instructions: this.rulePackage.instructions,
        ruleTimeRanges: this.rulePackage.ruleTimeRanges,
        actions: this.rulePackage.actions,
        severity: this.rulePackage.severity,
        includeSiteNotifications: this.rulePackage.includeSiteNotifications,
        autoResolve: this.rulePackage.autoResolve,
        bankHoliday: (this.rulePackage.bankHoliday ? 'Y' : 'N'),
        gatewayId: this.rulePackage.gatewayId,
        integration: this.rulePackage.integration,
        groupings: this.rulePackage.groupings
      };

      const route = `/rules/${this.rulePackage.id}/update`;
      this.apiService
        .postRoute(route, 'transaction-start')
        .then(() => {

          this.apiService.putRulePackage(id, data)
            .then((response) => {
              if (response?.errorMessage) {
                this.pleaseWait = false;
                this.apiService
                  .postRoute(route, 'transaction-error', String(response.errorMessage))
                  .then(() => {
                    this.apiService.toastWarn('There was a problem.', 'Unable to update at the moment, please try later.');
                  });
              } else {
                this.rulepackageService.patchExisting(this.rulePackage, data, response).then(() => {
                  this.apiService.toastSuccess(null, 'Rule updated.');
                  this.apiService
                    .postRoute(route, 'transaction-end')
                    .then(() => {
                      this.pleaseWait = false;
                      this.storeService.setDataChanged('rule', 'updated', data);
                      this.goBack();
                    });
                });
              }
            });
        });
    } else {
      this.rulePackage.orgId = this.apiService.getUserOrg().id;

      const route = `/rules/create`;
      this.apiService.postRoute(route, 'transaction-start')
        .then(() => {
          this.apiService.postRulePackage(this.rulePackage, makeCopy)
            .then(newId => {
              if (+newId) {
                this.apiService.toastSuccess(null, 'Rule created.');
                this.rulePackage.id = +newId;
                this.apiService.postRoute(route, 'transaction-end').then(() => {
                  this.rulepackageService.patchExisting(this.rulePackage, { id }, {}).then(() => {
                    this.pleaseWait = false;
                    this.storeService.setDataChanged('rule', 'created', this.rulePackage);
                    if (makeCopy) {
                      this.router.navigate(['/rules']);
                    } else {
                      this.goBack();
                    }
                  });
                });
              } else {
                this.pleaseWait = false;
                this.apiService
                  .postRoute(route, 'transaction-error', String(newId))
                  .then(() => {
                    this.apiService.toastWarn('There was a problem.', 'Unable to create at the moment, please try later.');
                  });
              }
            })
            .catch((e) => {
              this.pleaseWait = false;
              console.log(e);
            });
        });
    }
  }

  cancelRule() {
    this.goBack();
  }

  goBack() {
    if (this.ruleId) {
      this.router.navigate(['/rules', this.ruleId]);
    } else {
      this.router.navigate(['/rules']);
    }
  }

  deleteRule() {
    this.showDialog.set('delete');
  }

  confirmedDeleteRule(event: any) {
    this.showDialog.set(null);
    if (!event) {
      this.apiService.toastWarn('Cancelled', '');
      return;
    }
    this.pleaseWait = true;
    this.dialogIcon = 'fa fa-trash';
    const route = `/rules/${this.rulePackage.id}/delete`;
    this.apiService
      .postRoute(route, 'transaction-start')
      .then(() => {
        this.apiService
          .deleteRulePackage(this.rulePackage.id)
          .then(r => {
            if (!Array.isArray(r)) {
              this.apiService
                .postRoute(route, 'transaction-error', r.errorMessage)
                .then(() => {
                  this.apiService.toastWarn('There was a problem.', 'Unable to delete at the moment, please try later.');
                  this.pleaseWait = false;
                });
            } else {
              this.apiService.toastSuccess(null, 'Rule deleted.');
              this.apiService
                .postRoute(route, 'transaction-end')
                .then(() => {
                  this.storeService.setDataChanged('rule', 'delete', this.rulePackage);
                  this.router.navigate(['/rules']);
                });
            }
          });
      });
  }

  addNotification(type: string) {
    if (!this.orgUsers) {
      this.apiService.getUsersForOrg().then(users => {
        this.orgUsers = users.filter(user => user.isActive);
      });
    }
    this.showDialog.set('notification');

  }

  userSelected(user?: any) {
    this.showDialog.set(null);

    if (!user) {
      return;
    }
    let rl = new RuleNotification(null, 'email', user.email);
    this.rulePackage.notifications.push(rl);
  }

  addEmailNotification() {
    let rl = new RuleNotification(null, 'email', this.email);
    this.rulePackage.notifications.push(rl);
    // Clean up selection values
    this.addingNotification = null;
    this.selectedEmail = null;
  }

  removeNotification(index: number) {
    this.rulePackage.notifications.splice(index, 1);
  }

  removeCondition(index: number) {
    this.rulePackage.conditions.splice(index, 1);
  }

  removeAction(index: number) {
    this.rulePackage.actions.splice(index, 1);
  }

  drillInto(target: any, event: any) {
    this.selectedAsset = target;
    this.isShowingPopupSensorData = true;
  }

  toggleBankHoliday() {
    this.rulePackage.bankHoliday = !this.rulePackage.bankHoliday
  }

  enableRule() {
    this.setRuleIsEnabled(true);
  }

  disableRule() {
    this.setRuleIsEnabled(false);
  }

  setRuleIsEnabled(state: boolean) {
    this.pleaseWait = true;
    this.apiService.enableRulePackage(this.rulePackage.id, state)
      .then(() => {
        this.storeService.setDataChanged('rule', 'updated', this.rulePackage);
        this.apiService.toastSuccess(null, 'Rule ' + (state ? 'enabled' : 'disabled') + '.');
        this.goBack();
      });
  }

  copyRule() {
    this.submitRule(true);
  }

}

@defer (when controls()) {
  @if (canControl) {
    <article>
      <table class="table">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Controls</th>
          </tr>
        </thead>
        <tbody>
          @for (asset of controls().assets; track asset.asset_id) {
            <tr>
              <td>{{ asset.title }}</td>
              <td>
                @for (control of asset.controls; track control.uuid) {
                  <app-button [label]="control.title" (click)="clickControl(asset, control)" />
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </article>
  }
} @placeholder {
  <i class="mdi-loading mdi mdi-spin-x2"></i>
}
@if (!canControl) {
  <app-info-panel>You do not have permission to operate this asset</app-info-panel>
}

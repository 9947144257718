@defer (when issue()) {
  @if (hasPageTitle && asset) {
    <app-page-title [canGoBack]="true" [label]="asset.gateway.title + ',  ' + asset.title" [routerLink]="['/issuetracking']" />
  }
  <main class="content-container">
    <header>
      <app-toolbar>
        <div class="right">
          <a href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ issue().documentKey }}" target="_blank">
            <app-button label="View Review" icon="mdi mdi-book-open-variant-outline" />
          </a>
          <i class="mdi mdi-circle-small divider"></i>
          <app-button (click)="ChangeStatus()" label="Change Status" icon="mdi mdi-pencil" />
        </div>
      </app-toolbar>
    </header>
    <section>
      <div>
        <div>
          <h1>
            {{ issue().annotation }}
          </h1>
        </div>
        <div class="flex">
          <div class="flex-1"><span>Status</span> {{ issue().status }}</div>
          <div class="flex-1"><span>Start</span> {{ start | date: 'dd MMMM YY' }}</div>
          <div class="flex-1"><span>End</span> {{ end | date: 'dd MMMM YY' }}</div>
        </div>
      </div>
    </section>
    <section>
      <h1>Sensor</h1>
      <div>
        <p>{{ issue().assetTitle }}</p>
        <div class="chart" [class.has-telemetry]="asset && telemetry() && annotations()">
          @if (asset && telemetry()?.length && annotations()) {
            <app-d3-chart [asset]="asset" [hasToClickToInteract]="true" [canAnnotate]="true" [canZoom]="false" [height]="200" [telemetry]="telemetry()" [annotations]="annotations()" [showLegend]="true" [minMaxFromData]="true" [penWidth]="0.8" [dateFrom]="start" [dateTo]="end" />
          } @else {
            <app-preloader />
          }
        </div>
      </div>
    </section>
    <section>
      <h2>Comments</h2>
      <app-issue-tracking-comments [issue]="issue()" />
    </section>
    <!--
    <section>
      <h2>Audit</h2>
      <app-issue-tracking-audit [issue]="issue()"></app-issue-tracking-audit>
    </section>
  --></main>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

@switch (dialogOpen()) {
  @case ('status') {
    <app-dialog header="Change Status" (onClose)="dialogOpen.set(null)" [dimensions]="{ width: 520, height: 410 }">
      <section class="statuses">
        <div (click)="setStatus('open')">
          <app-button label="This issue is open" [isBlock]="true" [isDisabled]="issue().status === 'open'" />
        </div>
        <div (click)="setStatus('in progress')">
          <app-button label="This issue is in progress" [isBlock]="true" [selected]="wantsStatus() === 'in progress'" />
        </div>
        <div (click)="setStatus('no action required')">
          <app-button icon="mdi mdi-check" label="There is no action required and has been completed" [isBlock]="true" iconPos="right" [selected]="wantsStatus() === 'no action required'" />
        </div>
        <div (click)="setStatus('completed')">
          <app-button icon="mdi mdi-check" label="This has been completed" [isBlock]="true" iconPos="right" [selected]="wantsStatus() === 'completed'" />
        </div>
      </section>
      @if (wantsStatus() === 'in progress' || wantsStatus() === 'completed') {
        <section class="mt-1 notes content-container">
          <h4>Proposed Action</h4>
          <textarea class="fourd" rows="3" [(ngModel)]="proposedAction"></textarea>
        </section>
      }
      @if (wantsStatus()) {
        <section class="text-right mt-1">
          <app-button icon="mdi mdi-check" (click)="saveStatus()" label="Save" />
        </section>
      }
    </app-dialog>
  }
}

@defer (when gateways) {
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th>Gateway</th>
        <th>Site</th>
        <th class="hidden-xs">Location</th>

        @if (orgCount > 1) {
          <th>Org</th>
        }
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (gateway of gateways(); track gateway.id) {
        @if (show === 'all' || (show === 'favourites' && favs[gateway.id])) {
          <tr [class.active]="gateway.selected">
            <td (click)="selectGateway(gateway)"><app-tick [selected]="gateway.selected" />&nbsp; <app-gateway-indicator [gateway]="gateway"></app-gateway-indicator>{{ gateway.title }}</td>
            <td (click)="selectGateway(gateway)">{{ gateway.siteTitle ?? gateway.site?.title }}</td>
            <td class="hidden-xs" (click)="selectGateway(gateway)">{{ gateway.location }}</td>

            @if (orgCount > 1) {
              <td (click)="selectGateway(gateway)">{{ gateway.orgTitle ?? gateway.org?.shortTitle }}</td>
            }
            <td><app-fav-icon [isSelected]="gateway.isFav" (onFavChanged)="clickFav(gateway)" /></td>
          </tr>
        }
      }
    </tbody>
  </table>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

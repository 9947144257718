<div>
  <div class="title" (click)="navigateBack()">
    <span pTooltip="Back to floorplan">
      <i class="fa fa-fw fa-chevron-left"></i>
      <span> </span> {{ shape.title }}&nbsp;</span
    >
  </div>
  <div>
    <div class="tabview-list">
      <div [class.active]="tabIndex() === 0" (click)="tabIndex.set(0)"><i class="mdi mdi-view-list"></i> Telemetry</div>
      @if (shape.category === 'Occupancy') {
        <div [class.active]="tabIndex() === 1" (click)="tabIndex.set(1)"><i class="mdi mdi-pencil"></i> Manage Occupancy</div>
      }
      <div [class.active]="tabIndex() === 2" (click)="tabIndex.set(2)"><i class="mdi mdi-electric-switch"></i> Control</div>
      <div></div>
    </div>

    @switch (tabIndex()) {
      @case (1) {
        <app-shape-manage [shape]="shape"></app-shape-manage>
      }
      @case (2) {
        <app-site-plan-shape-control [shape]="shape" />
      }
      @default {
        <app-setpoint-query [assets]="shape.assets" view="compact" [showValue]="true" [isSetpointable]="true"></app-setpoint-query>
      }
    }
  </div>
</div>

import { Component, input, model, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from 'app/classes/asset';
import { IDataClassConfig } from 'app/classes/data-table/data-table';
import { Telemetry } from 'app/classes/telemetry';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { DateService } from 'app/shared/date.service';
import { IssueTackingStatusType, IssueTrackingService, ITrackingIssue } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-issue-tracking-item',
  standalone: false,
  templateUrl: './issue-tracking-item.component.html',
  styleUrl: './issue-tracking-item.component.css'
})
export class IssueTrackingItemComponent implements OnInit {
  issue = model<ITrackingIssue>();
  telemetry = signal<Telemetry[]>(null);
  dataForm: DataForm;

  start: Date;
  end: Date;
  asset: Asset;
  annotations = signal<any[]>(null);
  hasPageTitle = false;
  dialogOpen = signal<'status'>(null);
  wantsStatus = signal<IssueTackingStatusType>(null);

  proposedAction: string;

  constructor(private buildingReviewService: BuildingsService, private issueTracking: IssueTrackingService, private assetService: AssetService, private apiService: APIService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    const config: IDataClassConfig = {};
    this.dataForm = new DataForm({ data: this.issue() },);
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getIssue(id);
    } else {
      this.initTelemetry();
    }
  }

  getIssue(id: number) {
    this.hasPageTitle = true;
    this.issueTracking.getIssue(id).then((response) => {
      this.issue.set(response.issue);
      this.initTelemetry();
    });
  }

  initTelemetry() {
    this.start = this.issue().reviewDate;
    this.end = DateService.addMonthsToEndOfMonth(this.start, this.issue().noOfMonths - 1);
    this.getTelemetry();
  }

  getTelemetry() {
    this.assetService.getAsset(this.issue().asset_id).then((asset: any) => {
      this.asset = asset;
      this.assetService.getTelemetry(this.issue().asset_id, this.start, this.end, 60 * 60).then((response: any) => {
        const telemetry: Telemetry[] = response.telemetry;
        this.telemetry.set(telemetry);
      });
    });

    this.assetService.getAnnotations(this.issue().asset_id).then(this.annotations.set);
  }


  ChangeStatus() {
    this.dialogOpen.set('status');
  }

  setStatus(status: IssueTackingStatusType) {
    this.wantsStatus.set(status);
  }

  async saveStatus() {
    this.apiService.toastSuccess('setting status...', null, 500);
    await this.issueTracking.setStatus(this.issue().id, this.wantsStatus(), this.proposedAction);
    this.apiService.toastSuccess('status set.');
    this.router.navigate(['/issuetracking']);
  }
}

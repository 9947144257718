<article>
  @defer (when offlineCountAssets() !== null) {
    <div pTooltip="Updated at {{ lastCheck() | date: 'HH:mm:ss' }}">
      <span [class.no-issues]="!hasOfflineItems()">
        @for (message of messages(); track message) {
          <div>{{ message }}</div>
        }
      </span>
    </div>
  } @placeholder {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</article>

@defer (when hasLoaded()) {
  @if (!isEnabled()) {
    <app-info-panel state="warning">Issue tracking not enabled</app-info-panel>
  } @else {
    @if (dataTable) {
      <app-toolbar>
        <div class="right">
          <app-button icon="mdi mdi-rotate-3d-variant" label="Open" (click)="toggleOpen()" [isDisabled]="true"></app-button>
        </div>
      </app-toolbar>

      <app-data-table [dataTable]="dataTable" (onRowClick)="rowClick($event)" [canFilterByAssetType]="false"> </app-data-table>
    } @else {
      @if (issues()) {
        <app-info-panel>No Outstanding Issues </app-info-panel>
      }
    }
  }
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

import { Component, OnInit, signal } from '@angular/core';
import { StoreService } from '../../shared/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { Site } from '../../classes/site';
import { CombinedCollectionItem, SiteService } from 'app/shared/site.service';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';

@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.css'],
  standalone: false
})
export class SiteDashboardComponent implements OnInit {

  siteId: number;
  subtitle: string = 'Loading...';
  site = signal<Site>(null);
  orgUsers: any[] = [];
  orgUsersAvailable: any[] = [];
  tabIndex = signal<number>(0);
  combinedCollections = signal<CombinedCollectionItem[]>(null);
  rulePackages = signal<RulePackage[]>(null);
  dataTableRules: DataTable;

  constructor(public apiService: APIService, private router: Router, private route: ActivatedRoute, private siteService: SiteService) {
    route.params.subscribe(params => {
      this.siteId = params['siteid'];
      apiService.getSiteExtended(this.siteId).then(site => {
        this.site.set(site);
        this.subtitle = `Site ${site.title}`;
        this.getRules();
      });
      this.getCollections();
    });
    const tab = this.route.snapshot.queryParamMap.get('tab');
    if (tab) {
      this.tabSet(+(['notifications', 'collections'].indexOf(tab) ?? 0));
    }
  }

  ngOnInit(): void {

  }

  rowClickRules(rule: RulePackage) {
    console.log(rule);
    this.router.navigate(['/rules', rule.id]);
  }

  getCollections() {
    this.siteService.getCollections(this.siteId).then(collections => this.combinedCollections.set(collections));
  }

  goBack() {
    this.router.navigate(['/org']);
  }

  getRules() {
    this.apiService.getRulePackages('alarms-conditions').then(rp => {
      rp = rp.sort(r => r.inAlert === 'Y' ? -1 : 1);
      this.rulePackages.set(rp);

      const dataClassConfig: IDataClassConfig = {
        "title": { "valueType": "string", "title": "Rule", "align": "left" },
        "inAlert": { valueType: "string", title: "Alarm", "align": "left", width: 80 }
      };

      this.dataTableRules = new DataTable({ data: rp }, dataClassConfig);
    });
  }

  tabSet(tabIndex: number) {
    if (tabIndex >= 0 && tabIndex < 2) {
      this.tabIndex.set(tabIndex);
    }
  }

}

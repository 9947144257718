@defer (when _sites()) {
  <article>
    <header>
      <app-toolbar size="small">
        <div class="right">
          <app-button label="Sites in alarm" size="small" (click)="clickSitesInAlarm()" icon="mdi mdi-alarm" />
          <app-button [label]="'Weather ' + (weatherOnMap ? 'on' : 'off')" size="small" icon="mdi mdi-cloud" (click)="toggleWeather()" />
        </div>
      </app-toolbar>
    </header>
    <section class="map">
      <div>
        @if (mapMarkers()) {
          <app-map mapKey="orgmap" [markers]="mapMarkers()"></app-map>
        }
      </div>
      @if (site) {
        <div class="site">
          <header class="close-panel text-right" (click)="site = null"><i class="fa fa-times"></i></header>
          <div>
            <app-explorer-site [site]="site" [showMapControls]="false" [showId]="false" (onSelected)="gatewaySelected($event)" [canNavigate]="true" [weather]="site.weather"></app-explorer-site>
          </div>
        </div>
      }
    </section>
  </article>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin"></i>
}

import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../shared/api.service';

@Component({
	selector: 'app-date',
	templateUrl: './date.component.html',
	styleUrls: ['./date.component.css'],
	standalone: false
})
export class DateComponent implements OnInit {
	_date: Date;
	dateFormat: string;
	@Input()
	set date(value: any) {
		if (value instanceof Date) {
			this._date = value;
		} else if (typeof value === "string") {
			this._date = new Date(value);
		} else if (parseInt(value)) {
			this._date = new Date(value);
		}
	}
	@Input()
	showTime: boolean;

	constructor(private apiService: APIService) {
		this.dateFormat = apiService.getDateFormat();
	}

	ngOnInit() {
	}

}

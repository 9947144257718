import { Component, OnInit, AfterViewInit, OnDestroy, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../shared/api.service';
import { User } from '../classes/user';
import { Site } from '../classes/site';
import { Org } from '../classes/org';
import { Subscription } from 'rxjs';
import { SiteService } from 'app/shared/site.service';

@Component({
  selector: 'app-org-dashboard',
  templateUrl: './org-dashboard.component.html',
  styleUrls: ['./org-dashboard.component.css'],
  standalone: false
})
export class OrgDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  LOCALSTORAGE_DEFAULT_TAB = 'orgdashboard:tab';
  LOCALSTORAGE_SITE_SEARCH = 'orgdashboard:sites:search';

  loading = true;
  org: Org = new Org();
  orgs: Org[] = [];

  user: User = new User();
  switchTo: Org; // true when switching org

  sites: Site[] = [];
  sitesFiltered: Site[] = []
  viewSites = true;
  site: Site;
  isAdmin: boolean;
  isWorking = signal<boolean>(false);


  isResticted: boolean;

  can = signal<ICanOrg>({
    swap: false,
    compliance: false,
    billing: false,
    integration: false,
    upload: false,
    map: true,
    sites: true,
    contacts: true,
    invite: false,
    orgAdmin: false,
    tenants: false,
    setpoints: false,
    occupancy: true,
    export: true,
    battery: true
  });

  userSubscription: Subscription;
  isListAvailable = true;
  searchText = '';
  tabId = signal<TabIds>('map');
  userTab = signal<number>(0);

  constructor(private siteService: SiteService, private router: Router, private apiService: APIService, private route: ActivatedRoute) {
    this.isAdmin = apiService.isAdmin();
    this.setTab(localStorage.getItem(this.LOCALSTORAGE_DEFAULT_TAB));
    this.searchText = localStorage.getItem(this.LOCALSTORAGE_SITE_SEARCH) || '';

    this.siteService.getSites().then((sites) => {
      this.sites = sites;
      this.searchSites();
    });

    if (this.apiService.isAdmin() || this.user.orgRoles.join(',').indexOf('swap_org') !== -1) {
      this.apiService
        .getOrgs('inactive=1')
        .then(orgs => {
          this.orgs = orgs.sort((a: Org, b: Org) => a.title > b.title ? 1 : -1);

        });
    }

    this.userSubscription = this.apiService.user.subscribe((user) => {
      if (user) {
        const canItem: ICanOrg = {
          orgAdmin: (user.role === 'admin' || user.orgRoles.join(',').indexOf('org_admin') !== -1),
          swap: (user.role === 'admin' || user.orgRoles.join(',').indexOf('swap_org') !== -1),
          compliance: (user.role === 'admin' || user.orgRoles.join(',').indexOf('compliance_admin') !== -1),
          integration: (user.role === 'admin' || user.orgRoles.join(',').indexOf('integration_admin') !== -1),
          billing: (user.role === 'admin' || user.orgRoles.join(',').indexOf('billing_admin') !== -1),
          tenants: (user.role === 'admin' || user.orgRoles.join(',').indexOf('tenant_admin') !== -1),
          upload: user.role === 'admin',
          map: !user.isRestricted,
          contacts: !user.isRestricted,
          invite: (user.role === 'admin' || user.orgRoles.join(',').indexOf('invite_user') !== -1),
          sites: !user.isRestricted,
          setpoints: (user.role === 'admin' || user.orgRoles.join(',').indexOf('org_admin') !== -1),
          battery: true
        };

        canItem.settings = canItem.integration || canItem.compliance || canItem.orgAdmin;

        this.can.set({ ...this.can(), ...canItem });

        this.isResticted = user.isRestricted;
        this.user = user;
        this.org = user.org;
      }
    });
  }

  onDidCapture(event: any) {
    this.isListAvailable = false;
    setTimeout(() => {
      this.isListAvailable = true;
    }, 500);
  }

  refreshCameraList() {
    this.isListAvailable = false;
    setTimeout(() => {
      this.isListAvailable = true;
    }, 100);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    window.scroll(0, 0);
  }

  gotoSite(site: Site) {
    console.log('going to site', site);
    this.site = site;
    this.viewSites = false;
    this.router.navigate(['org', { outlets: { 'dashboard-site-list': [site.id], 'dashboard-asset-list': null } }]);
  }


  gotoSites() {
    this.viewSites = true;
    this.router.navigate(['org']);
  }


  setTab(tab: TabIds | string) {
    if (!isNaN(+tab)) {
      return;
    }

    this.tabId.set(<TabIds>tab);
    localStorage.setItem(this.LOCALSTORAGE_DEFAULT_TAB, tab);
  }

  searchSitesChanged() {
    localStorage.setItem(this.LOCALSTORAGE_SITE_SEARCH, this.searchText);
    this.searchSites();
  }

  searchSites() {
    if (this.searchText === '') {
      this.sitesFiltered = this.sites;

      return;
    }
    this.sitesFiltered = this.sites.filter(s => {
      const searchTextLower = this.searchText.toLowerCase();
      if (s.title.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }
      if (s.org.shortTitle && s.org.shortTitle.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }

      if (s.address.address1.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }

      if (s.address.addressPostcode.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }

      if (s.address.addressTown.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }

      if (s.address.addressCounty.toLowerCase().indexOf(searchTextLower) !== -1) {
        return true;
      }

      if (String(s.id) === this.searchText) {
        return true;
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}

export type TabIds = 'swap' | 'settings' | 'compliance' | 'billing' | 'integration' | 'upload' | 'map' | 'sites' | 'contacts' | 'invite' | 'orgAdmin' | 'tenants' | 'setpoints' | 'occupancy' | 'export' | 'battery';

export interface ICanOrg {
  billing?: boolean;
  compliance?: boolean;
  contacts?: boolean;
  export?: boolean;
  integration?: boolean;
  invite?: boolean;
  map?: boolean;
  occupancy?: boolean;
  orgAdmin?: boolean;
  setpoints?: boolean;
  sites?: boolean;
  swap?: boolean;
  tenants?: boolean;
  upload?: boolean;
  settings?: boolean;
  battery: boolean;
}

import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { BuildingReview } from 'app/classes/building-review';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class IssueTrackingService {

  static readonly API_URL = 'https://eapkrq89fk.execute-api.eu-west-2.amazonaws.com/v1';

  constructor(private apiService: APIService, private http: HttpClient,) { }

  addReview(review: BuildingReview): Promise<any> {
    const payload = {
      buildingId: review.building.id,
      start: DateService.dbDate(review.review.dates.start),
      end: DateService.dbDate(review.review.dates.end)
    };
    return this.post<any>(payload, 'reviews/add');
  }

  setStatus(id: number, status: IssueTackingStatusType, proposedAction: string) {
    return new Promise((resolve) => {
      this.post<any>({ status, proposedAction }, '', `id=${id}&a=status-set`).then(respose => {
        resolve(respose);
      });
    });

  }

  addComment(id, comment): Promise<any> {
    const payload = {
      comment
    };

    return this.post<any>(payload, '', `id=${id}&a=comment-add`);
  }

  deleteComment(id, comment): Promise<any> {

    return this.post<any>({ comment }, '', `id=${id}&a=comment-del`);
  }

  getAudit(uuid: string): Promise<IGetIssueResponse> {
    return new Promise((resolve) => {
      this.get<IGetIssuesResponse>('audit', `uuid=${uuid}`).then(auditResponse => {

        resolve(auditResponse);
      });
    });
  }

  getIssue(id: number): Promise<IGetIssueResponse> {
    return new Promise((resolve) => {
      this.get<IGetIssuesResponse>('', `id=${id}`).then(issueResponse => {
        if (issueResponse.issue) {
          issueResponse.issue.reviewDate = new Date(issueResponse.issue.reviewDate);
          issueResponse.issue.id = issueResponse.issue.issueTrackingId;
        }
        resolve(issueResponse);
      });
    });
  }

  getIssues(): Promise<IGetIssuesResponse> {
    return new Promise((resolve) => {
      this.get<IGetIssuesResponse>().then(issuesResponse => {
        if (issuesResponse.issues) {
          issuesResponse.issues.forEach(issue => {
            issue.reviewDate = new Date(issue.reviewDate);
            issue.createdAt = new Date(issue.createdAt);
            issue.id = issue.issueTrackingId;
          });
        }
        resolve(issuesResponse);
      });
    });
  }

  getAdmin(): Promise<any> {
    return this.get<any>('admin');
  }

  setAdmin(data: any): Promise<any> {
    return this.post<any>(data, 'admin');
  }

  enableForOrg(): Promise<any> {
    return this.post<any>({ isEnabled: true }, 'admin');
  }

  disableForOrg(): Promise<any> {
    return this.post<any>({ isEnabled: false }, 'admin');
  }

  get<T>(path: string = '', qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${IssueTrackingService.API_URL}/${path}?${qs}`;
      return this.http
        .get<T>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  post<T>(body, path: string = '', qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${IssueTrackingService.API_URL}/${path}?${qs}`;
      return this.http
        .post<T>(url, body, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }
}

export interface IGetIssuesResponse {
  issues?: ITrackingIssue[];
  master: { isEnabled: boolean };
}

export interface ITrackingIssue {
  id: number;
  annotation: string;
  documentKey: string;
  assetTitle: string;
  assetType_id: number;
  asset_id: number;
  buildingTitle: string;
  building_id: number;
  createdAt: Date;
  completedAt: Date;
  createdBy: number;
  document_id: number;
  noOfMonths: number;
  proposedAction: string;
  rag: string;
  reviewDate: Date;
  uuid: string; // the review uuid
  issueUUID: string;
  state: string;
  status: IssueTackingStatusType;
  siteTitle: string;
  comments: IIssueTrackingComment[];
}
export interface IGetIssueResponse {
  issue: ITrackingIssue;
  master?: { isEnabled: boolean };
}

export interface IIssueTrackingComment {
  id: number;
  comment: string;
  createdBy: number;
  createdByName: string;
  createdAt: Date;
  isMyComment: boolean;
  isEnabled: boolean;
  importance: 'normal' | 'high';
}

export interface IAuditItem {
  id: number;
  action: 'created' | 'status' | 'comment' | 'closed';
}

export type IssueTackingStatusType = 'open' | 'in progress' | 'completed' | 'no action required';

import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { AssetService, IOfflineCloudConnector } from 'app/shared/asset.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-assets-offline',
  templateUrl: './assets-offline.component.html',
  styleUrls: ['./assets-offline.component.css'],
  standalone: false
})
export class AssetsOfflineComponent implements OnInit, OnDestroy {
  asset = signal<Asset>(null);
  connectors = signal<IOfflineCloudConnector[]>(null);
  tabIndex = signal<number>(1);
  isLoading = true;
  offline: any[];
  sort: { current: any } = { current: {} };
  can = signal<{ issues: boolean }>({ issues: false });
  user = signal<User>(null);

  offlineAssetsSubscription: Subscription;
  offlineCloudConnectorsSubscription: Subscription;

  dataTableConnectors = signal<DataTable>(null);
  dataTableAssets = signal<DataTable>(null);

  constructor(private apiService: APIService, private assetService: AssetService) {

  }

  ngOnInit(): void {

    const dataClassAssetsConfig: IDataClassConfig = {
      "title": { "valueType": "string", "title": "Asset", "align": "left" },
      "assetTypeTitle": { "valueType": "string", "title": "Type", "align": "left" },
      "gatewayTitle": { "valueType": "string", "title": "Gateway", "align": "left" },
      "value": { "valueType": "number", "title": "Last value", "align": "right" },
      "updatedAt": { "valueType": "date", "title": "Last Updated", "align": "right", width: 150 }
    };

    this.offlineAssetsSubscription = this.assetService.offlineAssets.pipe(filter(assets => !!assets)).subscribe(assets => {
      this.offline = assets.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
      this.dataTableAssets.set(new DataTable({ data: this.offline, sortedColumns: null }, dataClassAssetsConfig));
      this.isLoading = false;
      this.filter();
    });

    const dataClassConnectorsConfig: IDataClassConfig = {
      "name": { "valueType": "string", "title": "Cloud Connector", "align": "left" },
      "gateway_id": { "valueType": "string", "title": "Gateway", "align": "left" },
      "lastSeenAt": { "valueType": "date", "title": "Last Seen", "align": "right", width: 150 }
    };

    this.offlineCloudConnectorsSubscription = this.assetService.offlineCloudConnectors.pipe(filter(cc => !!cc)).subscribe(connectors => {
      this.dataTableConnectors.set(new DataTable({ data: connectors, sortedColumns: null }, dataClassConnectorsConfig));
      this.connectors.set(connectors.sort((a, b) => a.lastSeenAt > b.lastSeenAt ? -1 : 1));
      this.filter();
    });


    try {
      const sort = localStorage.getItem('assets:offline:list:sort');
      if (sort) {
        this.sort = JSON.parse(sort);
      }

    } catch (e) {
      console.log(e);
    }

    // Allow developers to see this (disabled for now)
    // this.can.set({ issues: this.apiService.hasModule('experimental') });
  }

  assetClick(asset: Asset) {
    this.asset.set(asset);
  }

  filter() {
    let { field, state } = this.sort.current;
    const stateInt = state === 'asc' ? 1 : -1;
    switch (field) {
      case 'asset':
        this.offline.sort((a, b) => a.title > b.title ? stateInt : stateInt * -1);
        break;
      case 'type':
        this.offline.sort((a, b) => a.assetTypeTitle > b.assetTypeTitle ? stateInt : stateInt * -1);
        break;
      case 'value':
        try {
          this.offline.sort((a, b) => +a.value > +b.value ? stateInt : stateInt * -1);
        } catch (e) { }
        break;
      case 'updatedAt':
        this.offline.sort((a, b) => +a.updatedAt > +b.updatedAt ? stateInt : stateInt * -1);
        break;
      case 'gateway':
        this.offline.sort((a, b) => a.gateway.title > +b.gateway.title ? stateInt : stateInt * -1);
        break;
    }
  }

  headerClicked(field: string) {
    let state = this.sort.current?.state;
    // Only one field can be sorted
    switch (state) {
      case 'asc':
        state = 'desc';
        break;
      default:
        state = 'asc';
        break;
    }
    this.sort.current = { field, state };

    localStorage.setItem('assets:offline:list:sort', JSON.stringify(this.sort));
    this.filter();
  }

  ngOnDestroy(): void {
    try {
      this.offlineAssetsSubscription.unsubscribe();
      this.offlineCloudConnectorsSubscription.unsubscribe();
    } catch (e) { }
  }

}

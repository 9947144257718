import { Component, Input, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-dashboard-tile-data-offline',
  templateUrl: './dashboard-tile-data-offline.component.html',
  styleUrls: ['./dashboard-tile-data-offline.component.css'],
  standalone: false
})
export class DashboardTileDataOfflineComponent implements OnInit, OnDestroy {

  fetchEvery = 60000 * 60;
  hasValue: boolean;

  lastCheck = signal<Date>(null);

  _recent: any;
  @Input()
  public get recent(): any {
    return this._recent;
  }
  public set recent(v: any) {
    this.hasValue = !!v;
    this._recent = v;
    setTimeout(() => {
      this.hasValue = false;
    }, 1500);
  }

  timer: any;
  offlineCountAssets = signal<number>(null);
  offlineCountCloudConnectors = signal<number>(null);
  messages = signal<string[]>(null);

  hasOfflineItems = signal<boolean>(false);

  constructor(private assetService: AssetService) { }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  ngOnInit() {
    this.getTotals();
    this.timer = setInterval(() => {
      this.getTotals();
    }, this.fetchEvery);
  }

  getTotals() {
    this.lastCheck.set(new Date());
    this.assetService.getOfflineAll().then(response => {
      this.offlineCountAssets.set(response.assets.length);
      this.offlineCountCloudConnectors.set(response.connectors.length);
      this.hasOfflineItems.set(response.assets.length > 0 || response.connectors.length > 0);
      if (this.hasOfflineItems()) {
        const assetsOffline = response.assets.length;
        const connectorsOffline = response.connectors.length;
        const messages = [];
        if (assetsOffline) {
          messages.push(`${assetsOffline} assets offline`);
        }
        if (connectorsOffline) {
          messages.push(`${connectorsOffline} connectors offline`);
        }
        this.messages.set(messages);
      } else {
        this.messages.set(['All assets online']);
      }
    });
  }
}

@defer (when assets) {
  <div class="col-xs-12">
    <div class="component-list">
      <table class="table table-sm" aria-label="Assets">
        <thead>
          <tr>
            <th width="48px"></th>
            <th>Sensor</th>
            <th class="text-right">Updated</th>
            <th class="text-right">Value</th>
          </tr>
        </thead>
        <tbody>
          @for (asset of assets; track asset.id; let i = $index) {
            @if (i === 0 || asset.assetType_id !== assets[i - 1].assetType_id) {
              <tr class="heading" [class.off]="!selected.types[asset.assetType_id]">
                <td>
                  <label class="switch" title="Hide sensors of this type">
                    <input type="checkbox" name="typetoggle" [(ngModel)]="selected.types[asset.assetType_id]" />
                    <span class="checkboxslider round"></span>
                  </label>
                </td>
                <td colspan="3">
                  {{ asset.assetTypeTitle }}
                </td>
              </tr>
            }
            @if (selected.types[asset.assetType_id]) {
              <tr class="asset" (click)="selectAsset(asset)">
                <td></td>
                <td>
                  <app-asset-icon [asset]="asset"></app-asset-icon> {{ asset.title }}
                  @if (asset.location) {
                    <span>, {{ asset.location }}</span>
                  }
                </td>
                <td class="text-right">{{ asset.updatedAt | date: 'dd MMM YY HH:mm' }}</td>
                <td class="text-right">{{ asset.value }}</td>
              </tr>
            }
          }
        </tbody>
      </table>
    </div>
  </div>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

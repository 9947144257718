@if (report) {
  <div>
    <app-page-title [label]="report?.title" [canGoBack]="true" routerLink="/reports" />

    <div class="col-md-12">
      @if (!runReport && report) {
        <div class="toolbar-wrapper sticky">
          <app-toolbar>
            <div class="left">
              @if (reportConfig.startAt) {
                @if (selectedCount > 0) {
                  Report {{ selectedCount }} gateway{{ selectedCount !== 1 ? 's' : '' }} for
                } @else {
                  Select gateways for
                }
                {{ reportConfig.startAt | date: dateFormat }}.
              }
            </div>
            <div class="right">
              <!--
								<app-tick-button [title]="defaultTab === 0 ? 'Report on all gateways' : 'Report on all favourites'" [(selected)]="reportAllGateways"></app-tick-button>-->
              <app-button [isDisabled]="(selectedCount === 0 && !reportAllGateways) || !reportConfig.startAt" type="button" (click)="runReportClick()" label="Generate report" icon="mdi mdi-play" />
            </div>
          </app-toolbar>
        </div>
        <div>
          <div class="row">
            <div class="col-sm-6 calendar">
              <div class="config-block">
                @switch (report.dateSpan) {
                  @case ('week') {
                    <p-calendar [disabledDays]="[0, 2, 3, 4, 5, 6]" [(ngModel)]="reportConfig.startAt" [dateFormat]="getDateFormat().toLowerCase()" [locale]="en" [inline]="true" [showIcon]="true" [firstDayOfWeek]="1" />
                  }
                  @case ('month') {
                    <p-calendar [(ngModel)]="reportConfig.startAt" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2020:2021" [readonlyInput]="true" [inline]="true" [locale]="en" />
                  }
                }
              </div>
            </div>
            <div class="col-sm-6">
              <div class="config-block">
                <!--<br/>
							<h3 class="section">Report Info</h3>
							<div class="report-info">
								<p>{{details}}</p>
							</div>-->
              </div>
            </div>
          </div>
          <div>
            @if (report.forGateway === 'Y' && !reportAllGateways) {
              <div>
                <div class="tabview-list">
                  <div [class.active]="tabIndex() === 0" (click)="tabIndex.set(0)">All Gateways</div>
                  <div [class.active]="tabIndex() === 1" (click)="tabIndex.set(1)">Favourites</div>
                  <div></div>
                </div>

                <div class="component-list">
                  @switch (tabIndex()) {
                    @case (1) {
                      @if (gateways) {
                        <app-report-gateway-list [gateways]="gateways" show="favourites" (gatewayToggle)="gatewayToggle($event)" />
                      } @else {
                        <i class="mdi mdi-loading mdi-spin-x2"></i>
                      }
                    }
                    @default {
                      @if (gateways) {
                        <app-report-gateway-list [gateways]="gateways" (gatewayToggle)="gatewayToggle($event)" />
                      } @else {
                        <i class="mdi mdi-loading mdi-spin-x2"></i>
                      }
                    }
                  }
                </div>
              </div>
            }
          </div>
          <div>
            <div>
              <button class="btn btn-block btn-primary" [disabled]="(selectedCount === 0 && !reportAllGateways) || !reportConfig.startAt" type="button" (click)="runReportClick()">Generate report</button>
              <br />
            </div>
            <div></div>
          </div>
        </div>
      }
      @if (runReport) {
        <div class="generated">
          <div class="overview">
            @if (reportConfig.forAsset?.id) {
              <div>
                <div class="row">
                  <div class="col-md-2 col-sm-3">Asset</div>
                  <div class="col-md-10 col-sm-9">
                    <app-asset-overview [asset]="reportConfig.forAsset" [showLocation]="true" [showSite]="true"> </app-asset-overview>
                  </div>
                </div>
              </div>
            }
            @if (reportConfig.startAt) {
              <div>
                <div class="row date-from no-print">
                  <div class="col-sm-12 navigation" [class.disabled]="reportsRunning">
                    <div class="text-center">
                      <i *ngIf="report.dateSpan === 'week'" pTooltip="Back a week" tooltipPosition="bottom" class="fa fa-angle-double-left fa-fw" (click)="reportStartAt(-7)"></i>
                      <i [pTooltip]="report.dateSpan === 'week' ? 'previous day' : 'previous month'" tooltipPosition="bottom" class="fa fa-angle-left fa-fw" (click)="reportStartAt(-1)"></i>

                      <span class="report-date" *ngIf="report.dateSpan === 'week'">{{ startAt | amDateFormat: 'DD MMMM YYYY' }}</span>
                      <span class="report-date" *ngIf="report.dateSpan === 'month'">{{ startAt | amDateFormat: 'MMMM YYYY' }}</span>

                      <i [pTooltip]="report.dateSpan === 'week' ? 'next day' : 'next month'" tooltipPosition="bottom" class="fa fa-angle-right fa-fw" (click)="reportStartAt(1)"></i>
                      <i *ngIf="report.dateSpan === 'week'" pTooltip="forward a week" tooltipPosition="bottom" class="fa fa-angle-double-right fa-fw" (click)="reportStartAt(7)"></i>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          @if (runReport) {
            <!--
            <div class="tabview-list">
              @for (item of collection; track item; let i = $index) {
                <div [class.active]="reportTabIndex === i" (click)="reportTabIndex = i"><i class="mdi mdi-view-list"></i>{{ item.reportConfig.tabTitle }}</div>
              }
              <div></div>
            </div>
            <div class="tabview-content">
              <app-report-view [report]="collection[reportTabIndex].report" [reportConfig]="collection[reportTabIndex].reportConfig" (onLoaded)="hasLoaded()"> </app-report-view>
            </div>
          -->
            <div>
              <p-tabView [activeIndex]="reportTabIndex">
                <p-tabPanel [header]="item.reportConfig.tabTitle" *ngFor="let item of collection; let i = index">
                  <app-report-view [report]="item.report" [reportConfig]="item.reportConfig" (onLoaded)="hasLoaded()"> </app-report-view>
                </p-tabPanel>
              </p-tabView>
            </div>
          }
        </div>
      }
      @if (!runReport && !report) {
        <div><i class="fa fa-refresh fa-spin"></i></div>
      }
    </div>
  </div>
}

@defer (when orgs?.length) {
  <div>
    <div class="quick-swaps mb-1 mt-1">
      @for (item of orgSwaps; track item.id) {
        <div>
          @if (item.logo) {
            <img tooltipPosition="top" pTooltip="Logon as {{ item.title }}" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ item.logo }}" (click)="switchOrg(item)" alt="org logo" />
          }
        </div>
      }
    </div>

    <section>
      <div class="search">
        <app-search-input [searchText]="filtered.text.orgs" [resultCount]="filtered.counts.orgs" (onSearchChanged)="searchChanged('orgs', $event)" tooltipText="Search sites"></app-search-input>
      </div>
    </section>

    <div class="fixed-width mb-1">
      @if (!orgs.length) {
        <div>
          <i class="mdi mdi-loading mdi-spin-x2"></i>
        </div>
      } @else {
        @if (isMobile) {
          <table class="table table-hover table-sm org-list" aria-label="Org List">
            <thead>
              <tr>
                <th [colSpan]="showId ? 2 : 1">Org</th>
                <th class="icon"></th>
                <th colspan="2"></th>
                <th class="address isDesktop">Address</th>
                <th class="created-at isDesktop" (click)="sortOrgBy('createdAt')" pTooltip="Show latest orgs created">Created</th>
              </tr>
            </thead>
            <tbody>
              @for (item of orgs; track item.id; let i = $index) {
                <tr [class.inactive]="!item.isActive" [class.hidden]="!item.isActive && !showInactive" (click)="clickRow(item)">
                  @if (showId) {
                    <td>{{ item.id }}</td>
                  }
                  <td>
                    @if (item.logo) {
                      <img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ item.logo }}" alt="logo" />
                    }
                  </td>
                  <td [title]="item.type" class="icon">
                    <i class="fa fa-fw fa-{{ item.type }}"></i>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>
                    <span class="isDesktop">{{ item.shortTitle }}</span>
                    <div class="text-right">
                      <div class="isMobile text-right">
                        <app-button (click)="switchOrg(item)" label="Login" />
                      </div>
                    </div>
                  </td>
                  <td class="address isDesktop">{{ item.address1 }}</td>
                  <td class="isDesktop createdat">
                    {{ item.createdAt | date: 'dd/MM/YY' }}
                    <app-button label="Switch" (click)="switchOrg(item)" icon="mdi mdi-login" />
                  </td>

                  <td class="isDesktop">
                    @if (item.engagementType === 'all') {
                      <app-chip label="engagement" pTooltip="Engagement across all orgs" tooltipPosition="top" />
                    }
                    @if (item.engagementType === 'limited') {
                      <app-chip label="limited&nbsp;engagement" pTooltip="Engagement for this org only" />
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        } @else {
          @if (dataTable) {
            <app-data-table [dataTable]="dataTable" (onSortOrderChanged)="sortChanged($event)" (onRowClick)="switchOrg($event)" />
          }
        }

        <app-toolbar>
          <div class="right">
            <app-button label="Toggle inactive" icon="mdi mdi-rotate-3d-variant" (click)="toggleInactiveOrgs()" />
          </div>
        </app-toolbar>
      }
    </div>
  </div>
}

@if (isWorking()) {
  <app-dialog [dimensions]="{ width: 330, height: 160 }" [canInteract]="false">
    <div class="switch-org-dialog">
      <img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ switchToOrg?.logo }}" alt="logo" />
      <h2>Switching org...</h2>
    </div>
  </app-dialog>
}
